import { Box, Flex, Text, Image } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function RakeStructure() {
  return (
    <>
      <Flex flexDirection="column">
        <Box className="gray-box">
          <Text className="rake-structure-title">Rake Structure</Text>
          <Text className="rake-structure-text">
            At Cards52, we have curated our poker rake structure keeping YOU in
            mind. Having designed a unique and one of the most competitive rake
            structures in the entire online poker community, we believe in not
            burdening our players with rakes.
            <br />
            <br />
            Card52 is adamant on following the “No Flop, No Drop” rule. This
            means that the platform will not collect any fee/rake on cash tables
            before the flop is dealt. Whether you are playing a game of Texas
            Holdem Poker, Pot Limit Omaha (PLO), or Pot Limit Omaha 5 (PLO5),
            Cards52 offers the most transparent and legit rake structure.
            <br />
            <br />
            Besides, the rake charged on poker tournaments is the bare minimum
            of 10% (inclusive of GST). Keeping up with our promise of
            transparency, we furnish complete details of the rake structure at
            the time of registering into the tournament.
            <br />
          </Text>
        </Box>
        <Flex flexDirection="column" className="text-start">
          <Text className="rake-structure-sub-title">
            For Cash tables, refer to the tables below:
          </Text>
          <Text className="rake-structure-sub-title">For NLHE</Text>
          <Image
          className="rake-img"
          src={require(`./../../assets/images/rake-table-1.png`)}
        />
        <div>*Rake is inclusive of GST</div>
        <br/><br/>
        <Text className="rake-structure-sub-title">For POL & PLO5</Text>
          <Image
          className="rake-img"
          src={require(`./../../assets/images/rake-table-2.png`)}
        />
        <div>*Rake is inclusive of GST</div>
        <br/>
        <br/>
        </Flex>
      </Flex>
    </>
  );
}

export default RakeStructure;
