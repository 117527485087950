import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function RewardStore() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/rewardStore-img.png`)}
        />
        <Flex className="text-center">
          <pre className="static-page-title">Welcome to the </pre>
          <pre className="static-page-title-blue">Rewards Store</pre>
        </Flex>
        <p className="static-text">
          At Cards52 we believe that playing online games should not only be fun
          and engaging, but they should also be rewarding. Thus we ensure our
          players enjoy the most rewarding journey on Cards52.
          <br />
          <br />
          Every player is special to us and hence we have some great rewards for
          every player joining the Cards52 family. We’ll make sure you don’t
          join empty handed, our WELCOME BONUS will be a sweet welcome.
          <br />
          <br />
          But that is just the beginning, stay assured of rewards every step of
          the way. First deposit, consecutive deposits, you name it and we have
          special rewards on everything.
          <br />
          <br />
          Besides, our loyal players are our VIPs, thus we have loyalty programs
          which keep showering rewards on these VIPs from time to time as they
          advance ahead on the platform. So make sure to become our VIP and
          you’ll be up for a rewarding experience like never before.
          <br />
          <br />
          Good things take some time, and if we take time, we’ll make sure to
          deliver the best. We are currently curating a special and unique set
          of rewards for you, which will soon be updated. So stay tuned!
          <br />
          <br />
          <br />
          <br />
        </p>
        {/* <Box
          className="static-page-img-2"
          backgroundImage={require(`./../../assets/images/Group 32499.png`)}
        /> */}
      </Flex>
    </>
  );
}

export default RewardStore;
