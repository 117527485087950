import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function TournamentSchedule() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/tournament.png`)}
        />
        <Flex>
          <pre className="static-page-title">Tournament Schedule</pre>
        </Flex>
        <p className="static-text text-center">
          On Cards52 we make sure a player always has some table running, which
          they can join and start grinding. Thus, we have poker tournaments
          running 24x7 on the platform. So be rest assured you will find
          something to play everytime you log on to the site.
          <br />
          <br />
          Besides, whether you are a player with a hefty bankroll or a limited
          one, it shouldn’t stop you from playing. So all of Cards52’s
          tournaments are carefully curated to cater to every bankroll,
          including freerolls for all the freeroll lovers out there.
          <br />
          <br />
          Wait, wait! It still does not end there. Didn’t we tell you, Cards52
          is your one stop solution for all poker needs?! Well, it truly is! How
          can we stop at just one type of poker? We offer a wide variety of
          poker variants which players can play. From the favorite Texas Hold’em
          or different types of PLO. Gear up for a tournament fiesta on Cards52
          EVERYDAY!
          <br />
          <br />
          This page will list down all the daily tournaments running on Cards52,
          so make sure to bookmark this page.
          <br />
          <br />
          We are currently brewing the perfect mix of tournaments for you, so
          stay tuned as we will soon be updating the tournaments schedule on
          Cards52. 
          <br/>
          <br/>
          <br/>
        </p>
      </Flex>
    </>
  );
}

export default TournamentSchedule;
