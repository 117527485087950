import { Box, Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function Blog() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/image 71.png`)}
        />
        <Flex className="text-center">
          <pre className="static-page-title">Card52 Blogs</pre>
        </Flex>
        <Flex className="static-text text-center">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </Flex>
        <Flex flexDirection="row" alignSelf="center" gap={60} margin="50px">
          <GridItem>
            <Box
              className="grid-item"
              backgroundImage={require(`./../../assets/images/Rectangle 263.png`)}
            />
            <Text className="text-below-images">
              How to play Poker Online? Learn Poker Step By Step...
            </Text>
          </GridItem>
          <GridItem>
            <Box
              className="grid-item"
              backgroundImage={require(`./../../assets/images/Rectangle 265.png`)}
            />
            <Text className="text-below-images">
              Poker Combinations: Best Poker Combinations and Poker...
            </Text>
          </GridItem>
          <GridItem>
            <Box
              className="grid-item"
              backgroundImage={require(`./../../assets/images/Rectangle 264.png`)}
            />
            <Text className="text-below-images">
              How Many Players Can Play Poker? – Cards52
            </Text>
          </GridItem>
        </Flex>
      </Flex>
    </>
  );
}

export default Blog;
