import { Box, Flex, GridItem, Text } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function Promotions() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/promotions.png`)}
        />
        <Flex className="text-center">
          <Text className="static-page-title">Promotions</Text>
        </Flex>
        <Flex flexDirection="column">
          <Flex alignSelf="center" gap={60} margin="30px">
            <GridItem>
              <Box
                className="promotion-images"
                backgroundImage={require(`./../../assets/images/promotion-1.png`)}
              />
            </GridItem>
            <GridItem>
              <Box
                className="promotion-images"
                backgroundImage={require(`./../../assets/images/promotion-2.png`)}
              />
            </GridItem>
            <GridItem>
              <Box
                className="promotion-images"
                backgroundImage={require(`./../../assets/images/promotion-3.png`)}
              />
            </GridItem>
          </Flex>
          <Flex alignSelf="center" gap={60} margin="30px">
            <GridItem>
              <Box
                className="promotion-images"
                backgroundImage={require(`./../../assets/images/promotion-4.png`)}
              />
            </GridItem>
            <GridItem>
              <Box
                className="promotion-images"
                backgroundImage={require(`./../../assets/images/promotion-5.png`)}
              />
            </GridItem>
            <GridItem>
              <Box
                className="promotion-images"
                backgroundImage={require(`./../../assets/images/promotion-6.png`)}
              />
            </GridItem>
          </Flex>
        </Flex>

        <Flex className="static-text">
        <br />
          <br />
          We understand that regular games can get monotonous and what adds a
          dash of excitement is promotions. From time to time, Cards52 treats
          you with some unbelievable and exciting promotions.
          <br />
          <br />
          We never run out of stock when it comes to promotions on Cards52, and
          we promise to keep getting better and bigger with every promotion we
          offer.
          <br />
          <br />
          <br />
          <br />
        </Flex>
      </Flex>
    </>
  );
}

export default Promotions;
