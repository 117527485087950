import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function HoldemPoker() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/image 79.png`)}
        />
        <Flex className="text-center">
          <pre className="static-page-title">How to Play </pre>
          <pre className="static-page-title-blue">No Limit Holdem</pre>
        </Flex>
        <Flex className="static-text text-center">
          If you have ever played poker before or if you are a new player with
          an interest in playing the game, you would know that the game of poker
          is vast. Although POKER is one game, it has several variants, each of
          which are unique and come with their distinct set of rules.
        </Flex>
        <Flex className="static-text">
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          Among them No-Limit Hold'em, or Texas Hold’em is the most popular and
          commonly played variant of poker. This particular variant of poker has
          gained massive popularity and liking for obvious reasons. It is easy
          to understand and play, it is entertaining and challenging, and one
          can potentially earn good money if played well are just some of the
          reasons why No-Limit Hold’em has gained such massive popularity.
          <br />
          If you’ve ever watched a poker movie or show, or any content where you
          see the characters playing a game of poker, they’re most likely
          playing No-Limit Hold’em. That’s how widely this variant is played.
          <br />
          If you wish to play poker, you should probably start with Texas
          Hold’em for its simplicity and easy-to-understand nature. Here’s
          everything you need to know about how to play No-Limit Hold’em.
        </Flex>

        <Flex className="static-page-title text-center">
          What is No-Limit Hold’em Poker?
        </Flex>
        <Flex className="static-text">
          No-Limit Hold’em Poker is a popular and commonly played poker variant.
          Playing a game of No-Limit Hold’em Poker requires a minimum of two and
          a maximum of nine players. The game uses a standard deck of 52 cards
          <br />
          No-Limit Hold’em is also known as Texas Hold’em. The objective of
          every player in No-Limit Hold’em is to make the best 5-card poker hand
          to win the game and the pot.
          <br />
          Now that you know what No-Limit Hold’em is, let’s dive further to know
          the rules of No-Limit Hold’em.
        </Flex>

        <Flex className="static-page-title text-center">
          Rules of No-Limit Hold'em
        </Flex>
        <Flex className="static-text" flexDirection="column">
          Just like any other game or sport, No-Limit Hold’em poker also has a
          set of rules. Players must carefully study and remember these Texas
          Hold’em rules at all times.
          <br />
          <br />
          Here are the rules of No-Limit Hold’em:
          <ul>
          • Each Texas Holdem Poker player playing on the table gets two private
            cards, known as hole cards or face down cards. These are secret
            cards private to the player and only they can see it. Players must
            not reveal these cards to anyone, except in the showdown.
          </ul>
          <ul>
          •  Five common cards are placed face up on the table. These are known
            as community cards. The five cards are revealed in a series of
            three, one and one cards as the staking round progresses.
          </ul>
          <ul>
          • Players must make the best five-card poker hand to win the game. For
            this they can use a combination of either one, both or none of their
            hole cards and five, four, or three, of the community cards.
          </ul>
          <ul>
          •  The action on the table moves around in a clockwise direction,
            starting with the small blind followed by the big blind to the left
            and so on.
          </ul>
          <ul>• The best Texas Hold’em hand wins in the end.</ul>
        </Flex>
        <Flex className="static-text">
          You are now aware of the basic rules of Texas Hold’em Poker, let’s
          understand the betting rounds and actions that a player can take in a
          game.
        </Flex>

        <Flex className="static-page-title">Betting Actions</Flex>
        <Flex className="static-text">
          A game of Texas Hold’em has five betting actions that players can
          choose from. Here are the betting actions in No-Limit Hold’em:
        </Flex>
        <Flex className="static-page-title-gray">Check</Flex>
        <Flex className="static-text">
          A player has the option of using the ‘Check’ option if no bets have
          been placed in that round of betting. Checking basically means that
          the player does not have to put in any money or chips to stay active
          in the hand, unless someone else bets. Note that one cannot check if
          the previous player has bet.
        </Flex>
        <Flex className="static-page-title-gray">Call</Flex>
        <Flex className="static-text">
          In Texas Hold’em, to ‘Call’ means to match the previous bet. If a
          player before you has bet, and when the action is on you, you can call
          by putting in the same amount as the previous player to stay in the
          hand.
        </Flex>

        <Flex className="static-page-title-gray">Raise</Flex>
        <Flex className="static-text">
          To ‘Raise’ means to increase the amount of bet placed by the previous
          player. It is an aggressive move wherein you put in more amount than
          the previous player’s bet. This forces the players to either now match
          your higher bet or move out of the hand.
        </Flex>

        <Flex className="static-page-title-gray">Fold</Flex>
        <Flex className="static-text">
          Moving out of the betting action and the hand is known as a ‘fold.’
          Folding means that a player has quit the hand and given up any money
          that he might have put in the pot.{" "}
        </Flex>

        <Flex className="static-page-title-gray">All-in</Flex>
        <Flex className="static-text">
          When a player goes ‘All-in’ they put in all their chips into the game.
          Betting all your remaining chips is known as going all-in. If a player
          loses the hand after going all-in, they are out of the game since they
          have no chips left to play.{" "}
        </Flex>

        <Flex className="static-page-title">Betting Rounds</Flex>
        <Flex className="static-text">
          Once you understand the rules and the actions available in Texas
          Hold’em, you will next need to understand How to play No-Limit
          Hold’em. The step by step process of playing Texas Hold’em is similar
          to the betting rounds in Texas Hold’em. A normal game of No-Limit
          Hold’em poker involves four rounds of betting followed by a showdown
          if applicable. Here are the betting rounds in Texas Hold’em poker.
        </Flex>

        <Flex className="static-page-title-gray">Pre-flop</Flex>
        <Flex className="static-text">
          Once all the players on the table are dealt with their hole cards, the
          first round of betting begins. Generally, the action starts with the
          small blind, i.e, the player to the left of the dealer, however, in
          the preflop round, the action starts with the player to the left of
          the big blind. This is because before the cards are dealt, the small
          blind and big blind must have already put in their blinds.
          <br />
          In a pre-flop betting round in poker, the players decide whether they
          want to stay in the game or fold depending on the cards they have been
          dealt.
        </Flex>

        <Flex className="static-page-title-gray">Flop</Flex>
        <Flex className="static-text">
          After the pre-flop round of betting is completed, the first three
          community cards are revealed face-up. This set of three cards is known
          as the ‘flop.’
          <br />
          Then begins the second round of betting where the small blind acts
          first. Here the players play based on the best five-card hand they can
          possibly make using the flop.
        </Flex>

        <Flex className="static-page-title-gray">Turn</Flex>
        <Flex className="static-text">
          Once the betting on the flop is completed the dealer reveals the
          fourth community card known as the ‘turn.’ The third round of betting
          takes place on the urn starting with the small blind.
        </Flex>

        <Flex className="static-page-title-gray">River</Flex>
        <Flex className="static-text">
          After the betting on the turn is completed, the fifth and final card
          of the community cards is placed face up on the table. This fifth card
          is known as the river.
          <br />
          By now all players have their best five-card hand. The forth and final
          round of betting takes place starting with the small blind.
        </Flex>

        <Flex className="static-page-title-gray">Showdown</Flex>
        <Flex className="static-text">
          On completion of the final round of betting, if there are two or more
          players still left in the hand, they must reveal their hole cards.
          This action of the final players showing the hole cards is known as
          Showdown. At the showdown, the player with the best five-card hand
          wins the game/pot.
        </Flex>

        <Flex className="static-page-title text-center">
          No-Limit Hold’em Hand Rankings
        </Flex>
        <Flex className="static-text">
          The game of poker revolves around the concept of hand rankings. If you
          wish to play poker, the hand rankings are your bible, that’s how
          important hand rankings are. Every new player is advised to sit with
          the hand ranking chart in front of them while playing until they learn
          the rankings by-heart.
          <br />
          <br />
          In No-Limit Hold’em there are 10 different five-card hands that are
          ranked in order of the probability of their occurrence. The hand which
          is rare and has the least probability of occurrence is ranked highest,
          while the most common one is ranked lowest. The highest ranked hand is
          the strongest hand possible and can beat all other hands.
          <br />
          <br />
          Here is the list of different five-card Texas Holdem Poker hands that
          you can make while trying to win the Texas Hold’em poker game.
          <br />
          <br/>
          1. Royal Flush <br/>The highest ranked hand is the ‘Royal Flush.’ It is the
          best possible hand in Texas Hold’em. A Royal Flush is made up of five
          highest cards (A, K, Q, J, T) of the same suit in sequence. It is
          mandatory that all the cards of a royal flush must be of the same
          suit. For example, Ah Kh Qh Jh Th is a Royal Flush. It is an extremely
          rare hand with a possibility of 0.000154%.
          <br />
          <br />
          2. Straight Flush <br/>The second best poker hand is a ‘Straight Flush.’ A
          straight flush is made of any five sequential cards of the same suit.
          For example, Td 9d 8d 7d 6d is a straight flush. In instances where
          two players have a straight flush, the player with the higher straight
          flush wins the pot.
          <br />
          <br />
          3. Four of a Kind <br/>A ‘Four of a Kind’ is made of any four cards of the
          same rank. It is the third highest hand in poker. In a four of a kind,
          the suit of the cards does not matter. The only condition is that the
          hand should have four cards of the same rank accompanied by a random
          fifth card. For example, 5s 5c 5h 5d is a four of a kind. If two
          players have a four of a kind, the player with the higher rank of
          cards wins.
          <br />
          <br />
          4. Full House <br/>A ‘Full House’ consists of three cards of the same rak
          and the remaining two cards of a similar rank. For example, Kh Kc Kd
          9h 9s is a full house. It is also known as a full boat. In situations
          where two players have a full house, the player with the higher ranked
          three cards wins.
          <br />
          <br />
          5. Flush <br/>A ‘Flush’ is a five card hand including five random cards of
          the same suit. In a flush the rank of the cards does not have
          importance, unless two or more players have a flush. The only criteria
          is that all five cards must be of the same suit. For example, Js 8s 5s
          3s 2s is a flush.
          <br />
          <br />
          6. Straight <br/>A ‘Straight’ is five cards in sequence. Here the suit
          doesn’t matter as long as the five cards are in sequence. For example,
          8 9 T J Q is a straight. In case two players have a straight, the
          player with the higher straight wins.
          <br />
          <br />
          7. Three of a Kind <br/>Similar to four of a kind, a ‘Three of a Kind’ is a
          hand consisting of three cards of the same rank and the remaining two
          cards of any random rank. For example, Qh Qd Qs 4h 2c is a three of a
          kind. If two players have three of a kind, the player with the higher
          ranked three of a kind wins.
          <br />
          <br />
          8. Two Pair <br/>A ‘Two Pair’ hand has two cards of one rank, two cards of
          another rank, and one random card of any rank. For example, Kh Ks 6h
          6c 3d is a two pair hand. If two or more players have two pairs, the
          player with the highest pair wins. If the highest pair is also
          identical then the second pair is taken into consideration. If the
          second pair is also the same, the higher ranked fifth card wins.
          <br />
          <br />
          9. One Pair<br/> The second lowest hand ranking is ‘One Pair,’ wherein
          there are two cards of the same rank and the remaining three cards of
          random ranks. Example of one pair is 3d 3c Ah 9d 6s. If two players
          have one pair, the player with the highest pair wins. If both players
          have the same pair, the player with the highest third card wins. If
          the third card is the same the fourth card is considered, and if that
          too is the same the highest fifth card wins.
          <br />
          <br />
          10. High Card <br/>A ‘High Card’ is when a player does not have any of the
          above hands. It is when a player sits with a random set of five cards
          of different ranks and suits. In a high card, the winner is selected
          on the basis of the highest card. For example, Ad 8c 6h 5s 2h is a
          high card hand, and will be known as an Ace-high hand.
          <br />
          <br />
          Note, in cases where all five cards of the players are identical the
          pot is equally divided among the players in contention.
          <br />
          <br />
          Now that you have the basic knowledge and understanding of how
          No-Limit Hold’em is played, it’s now time to put your knowledge to
          test. You can start by playing online No-limit Hold’em poker games to
          start your poker journey. If you want to practice and learn the game
          before you start putting in your money you can play the free Texas
          Hold’em online poker games known as poker freerolls.
          <br/>
          <br/>
          <br/>
        </Flex>
      </Flex>
    </>
  );
}

export default HoldemPoker;
