import React from "react";
import "./header.css";
import {
  Flex,
  Text,
  Image,
  Link,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <Flex className="footer-content" gap={70}>
        <div className="footer-section">
          <Link href="/home">
            <Image
              className="footer-logo"
              src={require(`./../assets/images/logo.png`)}
            />
          </Link>
          <Flex className="social-icons" gap={10}>
            <a
              href="https://www.facebook.com/profile.php?id=100085612064805"
              target="_blank"
            >
              <FaFacebook />
            </a>
            <a href="https://twitter.com/Cards52Official" target="_blank">
              <FaTwitter />
            </a>
            <a
              href="https://www.instagram.com/cards.52official/"
              target="_blank"
            >
              <FaInstagram />
            </a>
            <a
              href="https://in.linkedin.com/company/cards52-com"
              target="_blank"
            >
              <FaLinkedin />
            </a>
          </Flex>
          <Text className="footer-text">
            Cards52 is on a mission to revolutionize the Indian gaming scene by
            becoming a one-stop solution for all your gaming needs. With a
            determination to keep players first and offering the top-class
            features, the Cards52 team is dedicated to making every game on the
            platform rewarding and entertaining for every player. Get grinding
            on Cards52 and get ready to hold all the cards! Let the games
            begin!!
          </Text>
        </div>
        <div className="footer-section">
          <Text className="footer-title">Explore</Text>
          <List className="footer-links">
            <ListItem>
              <Link href="/about-us">About Us</Link>
            </ListItem>
            <ListItem>
              <Link href="/contact-us">Contact Us</Link>
            </ListItem>
            <ListItem>
              <Link href="/terms-conditions">Terms & Conditions</Link>
            </ListItem>
            <ListItem>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </ListItem>
            <ListItem>
              <Link href="/legality">Legality</Link>
            </ListItem>
            <ListItem>
              <Link href="/FAQs">FAQ</Link>
            </ListItem>
            <ListItem>
              <Link href="TDS">TDS</Link>
            </ListItem>
            <ListItem>
              <Link href="rake-structure">Rake Structure</Link>
            </ListItem>
            <ListItem>
              <Link href="RNG-certification">RNG Certification</Link>
            </ListItem>
            <ListItem>
              <Link href="blog">Blog</Link>
            </ListItem>
          </List>
        </div>
        <div className="footer-section">
          <Text className="footer-title">Promotions</Text>
          <List className="footer-links">
            <ListItem>
              <Link href="/promotions">Promotion</Link>
            </ListItem>
            <ListItem>
              <Link href="#">VIP Program</Link>
            </ListItem>
            <ListItem>
              <Link href="#">Leaderboard</Link>
            </ListItem>
            <ListItem>
              <Link href="/tournament-schedule">Tournaments</Link>
            </ListItem>
            <ListItem>
              <Link href="reward-store">Rewards Store</Link>
            </ListItem>
            <ListItem>
              <Link href="responsible-gaming">Responsible Gaming</Link>
            </ListItem>
          </List>
        </div>
        <div className="footer-section">
          <Text className="footer-title">Downloads</Text>
          <List className="footer-links">
            <ListItem>
              <a
                href="https://play.google.com/store/apps/details?id=your_app_package_name_here"
                target="_blank"
              >
                <Image
                className="footer-image"
                  src={require(`./../assets/images/Playstore Button.png`)}
                  alt="Google Play Store"
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                href="https://apps.apple.com/app/your_app_name_here/idyour_app_id_here"
                target="_blank"
              >
                <Image
                className="footer-image"
                  src={require(`./../assets/images/App-store.png`)}
                  alt="Apple App Store"
                />
              </a>
            </ListItem>
          </List>
        </div>
      </Flex>
      <div className="copyright-bar">
        <Text className="copyright-text">
          ©2023 Cards52.com. All Rights Reserved.
        </Text>
      </div>
    </footer>
  );
};

export default Footer;
