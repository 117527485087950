import React from "react";
import {
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Link,
} from "@chakra-ui/react";
import "./staticPages.css";
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";

function Contact() {
  return (
    <>
      <Flex mt="40px">
        <Flex flexDirection="column" width="60%">
          <Text className="static-page-title">Contact Us</Text>
          <Text className="description">
            At Cards52 we are constantly striving to keep getting better and we
            would love to hear from you. Your feedback is valuable to us. For
            any inquiries, grievances or feedback, feel free to reach out to us.{" "}
          </Text>
          <br />
          <Text className="description">
            <FiMapPin /> 421, Gera's Imperium Grand, Patto Centre, Panaji, Goa
            403001
          </Text>
          <br />
          <Text className="description">
            <FiPhone />
            {"  "}+91 87999 07281
          </Text>
          <br />
          <Text className="description">
            <FiMail />
            {"  "}support@cards52.com
          </Text>
        </Flex>
        <Flex flexDirection="column" mt="20px" gap="20px" width="40%">
          <FormControl>
            <FormLabel className="text">Name</FormLabel>
            <Input className="name-input-box" />
          </FormControl>
          <FormControl>
            <FormLabel className="text">Email</FormLabel>
            <Input className="name-input-box" />
          </FormControl>
          <FormControl>
            <FormLabel className="text">Message</FormLabel>
            <Input className="message-input-box" />
          </FormControl>
          <Button className="send-button">Send Message</Button>
        </Flex>
      </Flex>
      <Flex className="maps-container">
        <iframe
          className="maps"
          src="https://www.google.com/maps/place/Cards52.com/@15.495476,73.8344014,16.61z/data=!4m6!3m5!1s0x3bbfc1b7d4fef4bf:0xa22a071c870de676!8m2!3d15.4950901!4d73.8338361!16s%2Fg%2F11tdc1vr4j?entry=ttu"
        ></iframe>

        {/* <iframe
          className="maps"
          width="600"
          height="450"
          frameBorder="0"
          src="https://www.google.com/maps/embed/v1/place?key=YOUR_API_KEY&q=Cards52.com,Goa"
        ></iframe> */}
      </Flex>
    </>
  );
}

export default Contact;
