import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function Omaha() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/image 78.png`)}
        />
        <Flex className="text-center">
          <pre className="static-page-title">How to Play </pre>
          <pre className="static-page-title-blue">Pot Limit OMAHA</pre>
        </Flex>
        <Flex className="static-text text-center">
          As one dives deeper into the world of poker, one realizes that it
          isn’t that shallow. The game of poker is massive. Inside the game of
          poker one will find more and more bifurcations, different types and
          categorisations that further keep widening the poker tree. And one of
          the first bifurcations you will come across is the variants of poker
          that exist.
          <br />
          <br />
          Although there are numerous variants of poker games, some are more
          widely played and popular than the others for multiple reasons. The
          more famous ones are easy to understand and play and they are also
          exciting. The lesser known ones may either be confusing or maybe they
          simply lack the fun element.
          <br />
          <br />
          While Texas Hold’em or No-Limit Hold’em is the most popular poker
          variant, the variant that can be considered to stand second to it is
          Omaha Poker. Although it is majorly similar to Texas Hold’em in terms
          of gameplay, rules and terms, there are some minor yet significant
          differences that set Omaha Poker apart.
          <br />
          <br />
          Omaha Poker is yet another interesting and skillful game to play. But
          what adds a layer of interestingness to Omaha Poker is that it is
          further divided into three types, namely, No Limit Omaha, Pot Limit
          Omaha and Omaha Hi-Lo.
          <br />
          <br />
          Among these the most famous one, which is often confused to be
          synonymous with Omaha Poker is Pot Limit Omaha. Widely abbreviated as
          PLO, it is the second most popular poker variant after Texas Hold’em.
          <br />
          <br />
          If you are wondering how to play Pot Limit Omaha, then do not worry,
          you are in the right place. In this article we will guide you in
          detail and acquaint you with all that there is to know about playing
          Pot Limit Omaha.
        </Flex>

        <Flex className="text-center">
          <pre className="static-page-title">What Is Pot Limit Omaha?</pre>
        </Flex>
        <Flex className="static-text text-center">
          As the name says, Pot Limit Omaha is the pot limit variant of the
          poker game. Like other Omaha games, a player is dealt with four hole
          cards (Yes, FOUR hole cards) and the objective is to make the best
          possible five card hand using the five community cards and four hole
          cards. Additionally, the player must mandatorily use two and only two
          of their hole cards to make the best hand.
          <br />
          <br />
          Unlike its counterpart Texas Holdem, which has no limits on betting,
          PLO sets limits on how much the players bet. The key difference that
          sets PLO apart from other poker variants, or even Omaha variants is
          that a player can only bet as much as the size of the active pot. Any
          player indulging in a betting or raising action cannot bet or raise
          more than the size of the pot. The size of the active pot sets a bar
          or a limit on how much the player can put in, hence the name Pot Limit
          Omaha.
        </Flex>

        <Flex className="text-center">
          <pre className="static-page-title">
            How Is Pot Limit Omaha Different From Texas Hold’em?
          </pre>
        </Flex>
        <Flex className="static-text text-center">
          If you are already familiar with Texas Hold'em will not find it very
          difficult to transition to Pot Limit Omaha Poker. Just a little
          mindfulness and presence of mind should do the trick. This is because
          the basic rules such as the game objective, betting rounds, betting
          actions, and the ten hand rankings remain the same for both variants,
          except for a few key differences. Three to be precise.
          <br />
          <br />
          The first and foremost differentiating factor that distinguishes Omaha
          Poker games from Texas Hold’em is the use of four hole cards. While in
          Texas Hold’em is dealt with only two hole cards, a game of Pot Limit
          Omaha deals four hole cards. This leads to the formation of bigger
          pots and complex decisions to be made.
          <br />
          <br />
          The second key difference is the number of hole cards used to make a
          hand. In a normal game of Texas Hold’em a player is free to use any
          number of hole cards. They can use both, one or none of his two hole
          cards. However, in Pot Limit Omaha, a player must compulsorily use two
          and only two cards from their hand to make a hand. The minimum and
          maximum number of hole cards allowed to be utilized is two.
          <br />
          <br />
          The third and most significant difference is the limit on betting and
          raising. Players are allowed to make a maximum raise that is equal to
          the size of the pot. Anything exceeding the pot is not allowed.
        </Flex>

        <Flex className="text-center">
          <pre className="static-page-title">Pot Limit Omaha Poker Types</pre>
        </Flex>
        <Flex className="static-text text-center">
          Pot Limit Omaha too has a few variants. Apart from the basic
          traditional PLO, the other variants include Pot Limit Omaha Hi/low,
          PLO 5, PLO 6, 5 cards Pot Limit Omaha Hi/low, Courchevel, Courchevel
          Hi/low. Among these the most common ones are PLO 5 and PLO 6.
          <br />
          <br />
          In PLO 5, the players are dealt five-hole cards instead of four to
          play with. Similarly, in PLO 6, a player is dealt with six hole cards.
          The remaining rules of the game remain the same.
        </Flex>

        <Flex className="static-page-title text-center">
          How To Play Pot Limit Omaha?
        </Flex>
        <Flex className="static-text" flexDirection="column">
          The step by step guide to playing PLO is similar to that of No-Limit
          Hold’em poker. Players must carefully study and be mindful of these
          steps to play Pot Limit Omaha at all times.
          <ul>
            • A game of Pot Limit Omaha starts with the small blind and big
            blind placing their blinds on the table. Every player on the table
            is then given four private cards or hole cards.
          </ul>
          <ul>
            • A game of PLO requires a minimum of two players and a maximum of
            ten players at the table.
          </ul>
          <ul>
            • The players must make their best five-card hand using only two of
            their hole cards and three of the community cards from the table.
          </ul>
          <ul>
            • Once the players get four-hole cards the first betting round known
            as Preflop round begins in a clockwise direction.
          </ul>
          <ul>
            • In the Pre-flop round, the players have their starting hands and
            they must bet, call or raise based on this starting gand. Here
            players must remember that they are allowed to use only two cards in
            their final five-card hand. So as an example it is important to know
            that if you hold three queens and one nine they still do not hold a
            three of a kind since they are only allowed to use only two of those
            queen cards.
          </ul>
          <ul>
            • After the first round of betting is completed, the flop, that is
            three of the five community cards are dealt on the table and the
            second round of betting begins clockwise starting with the small
            blind, the player to the left of the dealer.
          </ul>
          <ul>
            • After the second round of betting on the flop is completed, the
            fourth community card known as the turn opens up. Players now are
            aware of four hole cards and four community cards to make their
            respective decisions.
          </ul>
          <ul>
            • Subsequently, on completion of the third betting round, the fifth
            and final community card, the river, is revealed. The players now
            have all the cards to make their best card hand.
          </ul>
          <ul>
            • One more round of betting takes place on the river, and if there
            are two or more players still active in the hand, there is a
            showdown.
          </ul>
          <ul>
            • At the showdown the players in contention have to reveal their
            hole cards to decide who has the better five card hand.
          </ul>
          <ul>
            • The player having the best five-card hand is declared as the
            winner.
          </ul>
        </Flex>

        <Flex className="text-center">
          <pre className="static-page-title">
            Bet Size Rules In Pot-Limit Omaha
          </pre>
        </Flex>
        <Flex className="static-text text-center">
          As mentioned earlier, there is a limit on the betting value size in
          Pot Limit Omaha. Here are the bet sizing rules players must follow
          when playing a game of PLO.
          <br />
          Minimum raise - The permissible value of raise in Pot Limit Omaha
          poker must be equal to the previously placed bet or raise in that same
          round of betting. The minimum bet in Pot-limit Omaha poker is similar
          to the size of the big blind. This means that if a player wants to
          stay in the hand, the minimum they need to put in is the amount equal
          to the big blind, unless there is a raise. .
          <br />
          <br />
          Maximum Raise – Similarly, the maximum a player can raise takes into
          consideration the size of the pot or active pot, as well as the bets
          on the table and the amount the player must call before raising.
        </Flex>

        <Flex className="text-center">
          <pre className="static-page-title">Pot Limit Omaha Hands</pre>
        </Flex>
        <Flex className="static-text text-center">
          The poker hands in Pot Limit Omaha are similar to those in Texas
          Hold’em poker game hands. There are a total of ten poker hands ranked
          based on their probability of occurrence. Here are the ten hand
          rankings in Pot Limit Omaha.
          <br />
          <br />
          • Royal Flush
          <br />
          The most premium hand is the Royal Flush. It is a combination of Ace,
          King, Queen, Jack, and 10 of the same suit. Owing to its rare
          occurrence, a Royal Flush is the strongest hand in poker. No other
          hand combination can beat the Royal Flush. An example of Royal Flush
          is Ad Kd Qd Jd Td.
          <br />
          <br />
          • Straight Flush
          <br />A straight flush is a five card hand having consecutive ranks
          and having the same suit. For example 8h 7h 6h 5h 4h is a straight
          flush.
          <br />
          <br />
          • Four of a kind
          <br />
          Four of a kind is a hand which includes four cards of the same rank.
          Here the suits of the cards can be different, however all four cards
          should have the same rank. 8s 8d 8h 8c Ks is an example of four of a
          kind.
          <br />
          <br />
          • Full House
          <br />A Full House is composed of three cards of the same rank and the
          remaining two cards of a similar rank. In a full house the suits do
          not matter. For example, Qd Qh Qs 6h 6c is a full house.
          <br />
          <br />
          • Flush
          <br />A Flush is a combination of any five random cards having the
          same suit. The ranks of the cards can be anything, just the suit
          should be the same. For example, Kh 8h 5h 3h 2h. The ranks of cards
          come into the picture only when two or more players have a flush.
          <br />
          <br />
          • Straight
          <br />
          Five consecutive cards of random suits combine to make a Straight.
          Here the suits have no role to play. For example, Jc Th 9s 8c 7d is a
          Straight.
          <br />
          <br />
          • Three of a Kind
          <br />
          As the name suggests, Three of a kind is a hand which has three cards
          of the same ranks and different suits. The remaining two cards can be
          any random cards. An example of Three of a Kind is 9h 9s 9d 6s 2s.
          <br />
          <br />
          • Two Pair
          <br />A two pair hand includes two pairs of similar ranking cards
          each, and one random card. In other words, there must be two cards of
          one rank, two cards of another rank, and one card of any other rank.
          For example, Th Tc 5d 5h 4s is a two pair hand in PLO.
          <br />
          <br />
          <br />
        </Flex>
      </Flex>
    </>
  );
}

export default Omaha;
