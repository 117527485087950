import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function HowToPlayFantasy() {
  return (
    <>
      <Flex flexDirection="column">
        {/* <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/FAQs.png`)}
        /> */}
        <br/><br/>
        <Flex className="text-center">
          <pre className="static-page-title">How To Play Fantasy Sports?</pre>
        </Flex>
        <p className="static-text">
          {" "}
          <br />
          <br />
          In recent years, the fantasy sports market has witnessed exponential
          growth in India. And it justifies since India has a large market for
          sports. Fantasy sports pairs their favorite sport with entertainment
          and the opportunity to win real cash rewards. All these factors
          created a massive demand for fantasy sports in a brief period.
          <br />
          <br />
          Naturally, the number of platforms offering fantasy sports contests
          has also increased significantly catering to the high participation of
          fans in the sport. Fans also get a chance to stay in touch with their
          favorite sports and come close to real-time action.
          <br />
          <br />
          Fantasy Leagues let you walk a mile in the shoes of a team-owner and
          analyst, evaluating the different aspects of a game. They sharpen your
          skills of people-management and learning how to juggle the
          complexities of a sport as someone who is on the outside, but not
          quite at the same time.
          <br />
          <br />
          Because of the increased availability and affordability of the
          internet and cellphones, sports fans are now more connected. FIFS
          states that almost 130 million people partake in Fantasy Sports in one
          form or the other in India. While as a nation, Cricket is considered
          as a religion and it dominates the majority of the Fantasy Sports
          Market of the country, there is also a large chunk of the demographic
          who are keen on Fantasy Football, Fantasy Hockey, and Fantasy Kabaddi
          among others.
          <br />
          <br />
          In this article, you will find a step by step tutorial on How To Play
          Fantasy Sports and Acing It!
          <br />
          <br />
          <br />
          <b>What are Fantasy Sports?</b>
          <br />
          Fantasy sports is a skill-based competition played on an online
          platform. Here a player must create a virtual team consisting of real
          life players of a professional sport. These virtual teams then compete
          with teams created by other players based on their performance in a
          particular contest or a group of contests. Eventually the virtual team
          which performs the best performance among the contesting teams in that
          contest is declared as the winner. Like mentioned above, fantasy
          sports are played online on a website or a mobile app.
          <br />
          <br />
          <b>Top Fantasy Sports </b>
          <br />
          India is a growing market for fantasy sports, and players aren’t just
          playing one sport. Indian’s are more than just cricket. Players are
          indulging in multiple sports on fantasy gaming applications and sites.
          Some of the most popular ones are:
          <br />
          • Fantasy cricket
          <br />
          • Fantasy football
          <br />
          • Fantasy kabaddi
          <br />
          • Fantasy basketball
          <br />
          • Fantasy volleyball
          <br />
          • Fantasy hockey
          <br />
          • Fantasy baseball
          <br />
          • Fantasy American football
          <br />
          • Fantasy handball
          <br />
          <br />
          <br />
          <b>Earn Real Money by Playing Fantasy Sports Games</b>
          <br />
          <br />
          A user can play fantasy sports with free contests to get started with.
          Once users get accustomed to playing the fantasy league contests, they
          can hop on to stakes by playing with small amounts of money which can
          then help win massive real money prizes.
          <br />
          <br />
          Platforms give players the opportunity to play ultimate fantasy sports
          as per their interest and win mega prizes. However, it is important to
          remember that the prize money and the investment amount differ for
          each league contest. Thus be prepared and read the contest details
          before you join a contest. With the right skills and understanding of
          the game, it is possible to make real money with fantasy sports games.
          <br />
          <br />
          <br />
          <b>How To Play Fantasy Sports?</b>
          <br />
          <br />
          • Step 1: Install the App
          <br />
          Firstly, players must install the fantasy sports app on their device.
          Unfortunately most fantasy sports applications are not available on
          Google PlayStore or on App Store. However, Cards52, which offers
          fantasy sports, is allowed on these stores. So you can easily download
          the app on your device from Google PlayStore or App Store.
          Alternatively, you can visit the download section on the Cards52.com
          website to download the app.
          <br />
          <br />
          • Step 2: Create a Free Account
          <br />
          Once you have downloading the app, you can create a free account on
          Cards52. Simply open the app and register yourself.
          <br />
          <br />
          • Step 3: Find a Match
          <br />
          Once you have registered, log in to your account, and visit the
          fantasy sports section on the application. Here, you will see a list
          of matches that are running or will start soon. You can identify the
          matches by the team logos displayed. Select a match or contest you
          wish to join.
          <br />
          <br />
          • Step 4: Form Your Teams
          <br />
          The next step is to form your virtual team. A list of all actual
          players playing the given match from both sides will be displayed. You
          can start picking players regardless of the team they play for and
          create your own virtual team. Remember that you must create a squad of
          11 players, and then from these 11 select your favorite batsman,
          all-rounder, bowler, and wicket keeper.
          <br />
          <br />
          You will also need to select a Captain and Vice-Captain for your team,
          so do it wisely. It is important to be mindful of who you pick as your
          captain and vice captain for two reasons - you receive twice as many
          points as your captain, and you receive 1.X of the points scored by
          your Vice-Captain. Once you have done that proceed to save the squad.
          <br />
          <br />
          • Step 5: Select a contest
          <br />
          Thousands of users pool their participation tokens for a match. You
          may enter various contests, pool money, and play to win money.
          <br />
          <br />
          Unfortunately bank account details once added cannot be deleted.
          However, you can add new bank account details while requesting your
          cash out.
          <br />
          <br />
          • Step 6: Watch the Live Match
          <br />
          After you have created your virtual squad, it is now time for the
          players you have selected to perform well in the match. You just have
          to sit back and watch the match to see how your selected players are
          performing.
          <br />
          <br />
          • Step 7: Check Your Ranking and Make Money
          <br />
          After the match has ended, you will be able to see what your score is
          based on the performance of your players. Besides you will also be
          shown your ranking in that contest. Based on your rank compared to
          other players at their ranks, it will be determined whether or not you
          earned any money.
          <br />
          <br />
          <br />
          <b>Understand When and How to Edit Your Team</b>
          <br />
          <br />
          Cards52 also gives you the opportunity to edit your team after you
          have saved your team. However this is only valid till the contest
          begins. Once the contest begins editing of teams is not permitted. To
          edit your team after you have created your team, go to the 'My Team'
          option.
          <br />
          <br />
          Before the match starts, you will have the option to modify your team,
          by selecting and deselecting your team players. This is especially
          helpful durig cricket matches. After the match toss, you will know
          which players will be playing the given. If you have added a
          non-playing player to your virtual team, you may go and modify it; or
          else, you will not receive any points since your player is not playing
          at all.
          <br />
          <br />
          When you open your player list, you will see green and red dots
          displayed next to the player's name. The green dots represent players
          who are playing the given match, while the red dots represent those
          who are not participating in the contest.
          <br />
          <br />
          As soon as the match begins the team will be frozen as your final
          selection. No edits or modifications can be done after that.
          <br />
          <br />
          <br />
          <b>Fantasy Sports in India</b>
          <br />
          <br />
          The fantasy sports industry has recorded massive growth in India over
          the past few years. According to a report published by Deloitte, India
          is the largest fantasy sports market in the world, with over 15 crores
          of registered users and a total market size of Rs 34,000 crore.
          Furthermore, the industry is anticipated to grow at a compounded
          annual growth rate (CAGR) of 38% to reach a massive Rs 1,65,000 crore.
          <br /> <br />
          Among the variety of fantasy sports, Cricket is the most preferred
          sport for fantasy users. However, it will not be long until other
          sports like kabaddi, hockey, volleyball and football will also gain
          popularity in the coming few years. The rise of fantasy sports in
          India is giving rise to a new and innovative sporting culture in the
          country.
          <br /> <br />
          Although currently cricket is dominating the fantasy sports,
          eventually as other sports begin to gain more popularity, the fantasy
          sports industry will create new fans for sports other than cricket,
          thus opening up new avenues and possibilities in the long run.
          <br />
          <br />
          <br />
          <b>How to choose the best fantasy sports site?</b>
          <br />
          <br />
          •  Licensing: Check if the platform has the required licensing for
          hosting fantasy sports contests.
          <br /> <br />
          •  Payment methods: You should make sure to choose fantasy sports
          platforms that offer a variety of payment methods like UPI, net
          banking, credit/debit cards, etc. Additionally it is important to
          check if the platform offers your preferable payment method.
          <br /> <br />
          •  Bonuses: Several fantasy sports sites offer good bonuses and
          promotions to their players. Thus check out the bonuses and promotions
          available on the site.
          <br /> <br />
          •  Free play: Select fantasy sports websites that allow you to start
          playing free contests at the beginning.
          <br /> <br />
          •  Fantasy options: You should go for platforms that offer multiple
          fantasy league options.
          <br /> <br />
          • Prizes: Select an online fantasy sports platform that offers good
          prizes for the winners.
          <br /> <br />
          Now that you are aware of how fantasy sports work and how to play
          fantasy sports, join Cards52 today and start your journey. Put your
          sports skills to use by evaluating, analyzing and forecasting matches
          based on your understanding of the sport.
          <br /> <br />
          It is important to take into consideration multiple factors such as
          the recent form of the players, their past records, their past
          performance at that given venue and game format, the weather
          conditions at the venues, the stats of the opposing team and other
          while creating a fantasy sports team.
          <br />
          <br />
          <br />
        </p>
      </Flex>
    </>
  );
}

export default HowToPlayFantasy;
