import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  Image,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Box,
  Text,
  Flex,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { REACT_APP_API_URL } from "../utils/config";
import axios from "axios";

function LoginModal() {
  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const [currentModal, setCurrentModal] = useState("login");
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };
  const goToModal = (page) => {
    setCurrentModal(page);
  };

  const SignUpAPI = async () => {
    let data = {
      mobile: mobile,
    };
    try {
      const response = await axios.post(`${REACT_APP_API_URL}/register`, data);
      if (response.data.status) {
        toast({
          title: "Success",
          description: response.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        goToModal("signUpOTPScreen");
      } else {
        toast({
          title: "Failed",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const SignUpOTPVerify = async () => {
    const otpString = otp.join("");
    let data = {
      type: "1",
      mobile: mobile,
      otp: otpString,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/verify-otp`,
        data
      );
      if (response.data.status) {
        toast({
          title: "Success",
          description: response.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: "Failed",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const LoginUpOTPVerify = async () => {
    const otpString = otp.join("");
    let data = {
      type: "2",
      mobile: mobile,
      otp: otpString,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/verify-otp`,
        data
      );
      if (response.data.status) {
        toast({
          title: "Success",
          description: response.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: "Failed",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const resendOTPforSignup = async () => {
    let data = {
      type: "1",
      mobile: mobile,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/resend-otp`,
        data
      );
      if (response.data.status) {
        toast({
          title: "Success",
          description: response.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        goToModal("signUpOTPScreen");
      } else {
        toast({
          title: "Failed",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const resendOTPforLogin = async () => {
    let data = {
      type: "2",
      mobile: mobile,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/resend-otp`,
        data
      );
      if (response.data.status) {
        toast({
          title: "Success",
          description: response.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        goToModal("signUpOTPScreen");
      } else {
        toast({
          title: "Failed",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const resendOTPforForgotPassword = async () => {
    let data = {
      type: "3",
      mobile: mobile,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/resend-otp`,
        data
      );
      if (response.data.status) {
        toast({
          title: "Success",
          description: response.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        goToModal("signUpOTPScreen");
      } else {
        toast({
          title: "Failed",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const loginWithPassword = async () => {
    let data = {
      type: "2",
      mobile: email,
      Password: password,
    };
    try {
      const response = await axios.post(`${REACT_APP_API_URL}/login`, data);
      if (response.data.status) {
        toast({
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: "Failed",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const loginWithOTP = async () => {
    let data = {
      type: "1",
      mobile: mobile,
    };
    try {
      const response = await axios.post(`${REACT_APP_API_URL}/login`, data);
      if (response.data.status) {
        toast({
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        goToModal("loginOTPScreen");
      } else {
        toast({
          title: "Failed",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {currentModal === "login" && (
        <Modal 
        // isOpen={isOpen} onClose={onClose}
        >
          
          <ModalOverlay />
          <ModalContent className="login-modal">
            <ModalHeader className="modal-header">
              <ModalCloseButton />
              <Image
                className="modal-image"
                src={require(`../assets/images/logo.png`)}
              />
            </ModalHeader>
            <ModalBody alignSelf="center">
              <FormControl mb={5}>
                <Text className="modal-title text-center">LOGIN</Text>
                <Input
                  className="modal-input-box"
                  placeholder="Username/ Email / Mobile Number"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <Input
                  className="modal-input-box"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Flex flexDirection="row">
                <Checkbox className="chackbox-text">Remember Me</Checkbox>
                <Spacer />
                <Text
                  className="modal-bottom-text-red"
                  onClick={() => goToModal("forgotPassword")}
                >
                  Forgot Password
                </Text>
              </Flex>
              <Button
                className="modal-red-box"
                onClick={() => loginWithPassword()}
              >
                LOGIN
              </Button>
              <Flex className="modal-bottom-text-blue text-center">
                <Text onClick={() => goToModal("loginWithOTPScreen")}>
                  LOGIN via OTP
                </Text>
              </Flex>
              <Flex className="text-center" flexDirection="row">
                <Flex className="modal-bottom-text text-center">
                  Create an account ?{" "}
                </Flex>
                <Flex className="modal-bottom-text-red text-center">
                  <Text onClick={() => goToModal("signUp")}>Sign Up</Text>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {currentModal === "loginWithOTPScreen" && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent className="login-modal">
            <ModalHeader className="modal-header">
              <ModalCloseButton />
              <Image
                className="modal-image"
                src={require(`../assets/images/logo.png`)}
              />
            </ModalHeader>
            <ModalBody alignSelf="center">
              <FormControl mb={5}>
                <Text className="modal-title text-center">LOGIN</Text>
                <Input
                  className="modal-input-box"
                  placeholder="Enter Mobile Number"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </FormControl>
              {/* <FormControl>
                <Input className="modal-input-box" placeholder="Password" />
              </FormControl> */}
              <br />
              <Button className="modal-red-box" onClick={() => loginWithOTP()}>
                GET OTP
              </Button>
              <Flex className="modal-bottom-text-blue text-center">
                <Text onClick={() => goToModal("login")}>
                  LOGIN via PASSWORD
                </Text>
              </Flex>
              <br />
              <br />
              <Flex className="text-center" flexDirection="row">
                <Flex className="modal-bottom-text text-center">
                  Create an account ?{" "}
                </Flex>
                <Flex className="modal-bottom-text-red text-center">
                  <Text onClick={() => goToModal("signUp")}>Sign Up</Text>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {currentModal === "signUp" && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="login-modal">
              <ModalHeader className="modal-header">
                <ModalCloseButton />
                <Image
                  className="modal-image"
                  src={require(`../assets/images/logo.png`)}
                />
              </ModalHeader>
              <ModalBody alignSelf="center">
                <FormControl mb={15}>
                  <Text className="modal-title text-center">SIGN UP</Text>
                  <Input
                    type="number"
                    className="modal-input-box"
                    placeholder="Enter Mobile Number"
                    // maxLength={10}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    className="modal-input-box"
                    placeholder="Enter Sign Up/ Referral Code ?"
                  />
                </FormControl>
                <Flex className="text-center">
                  <Checkbox />
                  <Flex className="chackbox-text">
                    I certify that I am 18 year old & I agree to the{" "}
                  </Flex>
                  <Flex className="modal-bottom-text-blue">
                    {" "}
                    Term & Conditions
                  </Flex>
                </Flex>
                <br />
                <br />
                <Button className="modal-red-box" onClick={() => SignUpAPI()}>
                  GET OTP
                </Button>
                <Flex className="text-center" flexDirection="row">
                  <Flex className="modal-bottom-text text-center">
                    Already have an account ?{" "}
                  </Flex>
                  <Text
                    className="modal-bottom-text-red text-center"
                    onClick={() => goToModal("login")}
                  >
                    LOGIN
                  </Text>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}

      {currentModal === "signUpOTPScreen" && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="login-modal">
              <ModalHeader className="modal-header">
                <ModalCloseButton />
                <Image
                  className="modal-image"
                  src={require(`../assets/images/logo.png`)}
                />
              </ModalHeader>
              <ModalBody alignSelf="center">
                <FormControl mb={5}>
                  <Text className="modal-title text-center">SIGN UP</Text>
                  <Flex flexDirection="row">
                    <Checkbox className="chackbox-text">
                      OTP has been sent
                    </Checkbox>
                    <Spacer />
                    <Text
                      className="modal-bottom-text-blue"
                      onClick={() => goToModal("signUp")}
                    >
                      Change Number
                    </Text>
                  </Flex>
                  {otp.map((value, index) => (
                    <Input
                      key={index}
                      className="otp-input-box"
                      maxLength={1}
                      value={value}
                      gap={10}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                    />
                  ))}
                </FormControl>

                <Flex flexDirection="row" className="text-center">
                  <Image
                    className="stopwatch-img"
                    src={require(`../assets/images/stopwatch 2.png`)}
                  />
                  <Text className="modal-bottom-text-red">04:30</Text>
                </Flex>

                <Flex flexDirection="row" className="text-center">
                  <Text>Didn’t Receive OTP ?</Text>
                  <Text
                    className="modal-bottom-text-red"
                    onClick={() => resendOTPforSignup()}
                  >
                    RESEND
                  </Text>
                </Flex>
                <br />
                <Button
                  className="modal-red-box"
                  onClick={() => SignUpOTPVerify()}
                >
                  VERIFY
                </Button>

                <Flex flexDirection="row" className="text-center">
                  <Text>Already have a account ?</Text>
                  <Text
                    className="modal-bottom-text-red"
                    onClick={() => goToModal("login")}
                  >
                    LOGIN NOW
                  </Text>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}

      {currentModal === "forgotPassword" && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="login-modal">
              <ModalHeader className="modal-header">
                <ModalCloseButton />
                <Image
                  className="modal-image"
                  src={require(`../assets/images/logo.png`)}
                />
              </ModalHeader>
              <ModalBody alignSelf="center">
                <FormControl>
                  <Text className="modal-title text-center">
                    FORGOT PASSWORD
                  </Text>
                  <Input
                    className="modal-input-box"
                    placeholder="Username/ Email / Mobile Number"
                  />
                </FormControl>
                <br />
                <br />
                <Button className="modal-red-box">GET OTP</Button>

                <Flex
                  className="modal-bottom-text-blue text-center"
                  onClick={() => goToModal("login")}
                >
                  BACK
                </Flex>
                <br />
                <br />
                <br />
                <Flex className="text-center" flexDirection="row">
                  <Flex className="modal-bottom-text text-center">
                    Create an account ?{" "}
                  </Flex>
                  <Flex className="modal-bottom-text-red text-center">
                    <Text
                      className="modal-bottom-text-red"
                      onClick={() => goToModal("signUp")}
                    >
                      Sign Up
                    </Text>
                  </Flex>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}

      {currentModal === "forgotPasswordOTPScreen" && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="login-modal">
              <ModalHeader className="modal-header">
                <ModalCloseButton />
                <Image
                  className="modal-image"
                  src={require(`../assets/images/logo.png`)}
                />
              </ModalHeader>
              <ModalBody alignSelf="center">
                <FormControl mb={5}>
                  <Text className="modal-title text-center">
                    Forgot Password
                  </Text>
                  <Flex flexDirection="row">
                    <Checkbox className="chackbox-text">
                      OTP has been sent
                    </Checkbox>
                    <Spacer />
                    <Text
                      className="modal-bottom-text-blue"
                      onClick={() => goToModal("signUp")}
                    >
                      Change Number
                    </Text>
                  </Flex>
                  {otp.map((value, index) => (
                    <Input
                      key={index}
                      className="otp-input-box"
                      maxLength={1}
                      value={value}
                      gap={10}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                    />
                  ))}
                </FormControl>

                <Flex flexDirection="row" className="text-center">
                  <Image
                    className="stopwatch-img"
                    src={require(`../assets/images/stopwatch 2.png`)}
                  />
                  <Text className="modal-bottom-text-red">04:30</Text>
                </Flex>

                <Flex flexDirection="row" className="text-center">
                  <Text>Didn’t Receive OTP ?</Text>
                  <Text
                    className="modal-bottom-text-red"
                    onClick={() => resendOTPforForgotPassword()}
                  >
                    RESEND
                  </Text>
                </Flex>
                <br />
                <Button
                  className="modal-red-box"
                  onClick={() => SignUpOTPVerify()}
                >
                  VERIFY
                </Button>

                <Flex flexDirection="row" className="text-center">
                  <Text>Already have a account ?</Text>
                  <Text
                    className="modal-bottom-text-red"
                    onClick={() => goToModal("login")}
                  >
                    LOGIN NOW
                  </Text>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}

      {currentModal === "loginOTPScreen" && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className="login-modal">
              <ModalHeader className="modal-header">
                <ModalCloseButton />
                <Image
                  className="modal-image"
                  src={require(`../assets/images/logo.png`)}
                />
              </ModalHeader>
              <ModalBody alignSelf="center">
                <FormControl mb={5}>
                  <Text className="modal-title text-center">Enter OTP</Text>
                  <Flex flexDirection="row">
                    <Checkbox className="chackbox-text">
                      OTP has been sent
                    </Checkbox>
                    <Spacer />
                    <Text
                      className="modal-bottom-text-blue"
                      onClick={() => goToModal("login")}
                    >
                      Change Number
                    </Text>
                  </Flex>
                  {otp.map((value, index) => (
                    <Input
                      key={index}
                      className="otp-input-box"
                      maxLength={1}
                      value={value}
                      gap={10}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                    />
                  ))}
                </FormControl>

                <Flex flexDirection="row" className="text-center">
                  <Image
                    className="stopwatch-img"
                    src={require(`../assets/images/stopwatch 2.png`)}
                  />
                  <Text className="modal-bottom-text-red">04:30</Text>
                </Flex>

                <Flex flexDirection="row" className="text-center">
                  <Text>Didn’t Receive OTP ?</Text>
                  <Text
                    className="modal-bottom-text-red"
                    onClick={() => resendOTPforLogin}
                  >
                    RESEND
                  </Text>
                </Flex>
                <br />
                <Button
                  className="modal-red-box"
                  onClick={() => LoginUpOTPVerify()}
                >
                  VERIFY
                </Button>

                <Flex flexDirection="row" className="text-center">
                  <Text>Already have a account ?</Text>
                  <Text
                    className="modal-bottom-text-red"
                    onClick={() => goToModal("login")}
                  >
                    LOGIN NOW
                  </Text>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}

export default LoginModal;
