import React, { useState } from "react";
import { Box, Flex, Button, Image, Link } from "@chakra-ui/react";
import LoginModal from "../pages/ModalScreens/login";

const Header = ({ openLoginModal }) => {
  const [showPromotions, setShowPromotions] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [showPokerSubOptions, setShowPokerSubOptions] = useState(false);

  const togglePromotions = () => {
    setShowPromotions(!showPromotions);
    setShowHowToPlay(false);
    setShowPokerSubOptions(false);
  };

  const toggleHowToPlay = () => {
    setShowHowToPlay(!showHowToPlay);
    setShowPromotions(false);
  };

  const togglePokerSubOptions = (e) => {
    e.stopPropagation();
    setShowPokerSubOptions(!showPokerSubOptions);
  };

  const handleDownload =()=>{
    console.log('handleDownload');
    window.trackGLDownloadEvent();
  }

  return (
    <Box className="header-container" p={4}>
      <Flex gap={120}>
        <Link href="/home">
          <Image
            className="header-logo"
            src={require(`./../assets/images/logo.png`)}
          />
        </Link>
        <Flex alignItems="center">
          <div
            className={`nav-link ${showHowToPlay ? "active" : ""}`}
            onClick={toggleHowToPlay}
          >
            How to play
            {showHowToPlay && (
              <div className="popover">
                <Flex flexDirection="row">
                  <Link
                    className="popover-link"
                    onClick={(e) => togglePokerSubOptions(e)}
                  >
                    Poker
                  </Link>
                  <Link className="popover-link" href="/rummy">
                    Rummy
                  </Link>
                </Flex>
                {showPokerSubOptions && (
                  <div className={`sub-options`}>
                    <Link className="sub-option-link" href="/holdem-poker">
                    No Limit Hold’em
                    </Link>
                    <Link className="sub-option-link" href="/omaha">
                      Pot Limit Omaha
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="separator" />
          <div
            className={`nav-link ${showPromotions ? "active" : ""}`}
            onClick={togglePromotions}
          >
            Promotions
            {showPromotions && (
              <div className="sub-options">
                <Link className="sub-option-link" href="/#">
                  VIP Program
                </Link>
                <Link className="sub-option-link" href="/#">
                  Leaderboards
                </Link>
                <Link className="sub-option-link" href="/#">
                  Tournaments
                </Link>
                <Link className="sub-option-link" href="/reward-store">
                  Rewards Store
                </Link>
              </div>
            )}
          </div>
          <div className="separator" />
          <Link className="nav-link" href="/about-us">
            About us
          </Link>
          <div className="separator" />
          <Link className="nav-link" href="#" onClick={handleDownload}>
            Download
          </Link>
        </Flex>
        <Button className="login-button" onClick={openLoginModal}>
          LOG IN/SIGN UP
        </Button>
        <LoginModal/>
        <Image
          className="helpline-logo"
          src={require(`./../assets/icons/helpline.png`)}
        />
      </Flex>
    </Box>
  );
};

export default Header;
