import {
  Box,
  Flex,
  Grid,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function ResponsibleGaming() {
  return (
    <>
      <Flex flexDirection="column">
        {/* <Box
            className="static-page-img"
            backgroundImage={require(`./../../assets/images/image 80.png`)}
          /> */}
        <Flex>
          <pre className="static-page-title">Responsible Gaming : </pre>
        </Flex>
        <p className="static-text">
          <i>
            The games involve an element of financial risk and may be addictive.
            Please play responsibly and at your own risk.
          </i>
          <br />
          <br />
          Ayron Tech Private Limited (the “Company”), the operator of this
          online poker website, www.cards52.com (“Website”), strives to endorse
          responsible gaming as well as encouraging the prevention and avoidance
          of excessive gaming. This Responsible Gambling Policy sets out not
          only the Company’s commitments but also the players' responsibility to
          adhere to and promote responsible gambling practices and minimize the
          negative effects of excessive gaming.
          <br />
          <br />
          At Cards52, we are driven to ensure that you enjoy your gaming
          experience on our platform while remaining fully aware of the social
          and financial effects associated with excessive gaming. We offer and
          promote our skill games as an enjoyable entertainment activity. We
          believe that gaming can remain enjoyable only if you play responsibly
          and stay in control.
          <br />
          <br />
          <br />
          <b>WHAT YOU CAN DO:</b>
          <br />
          <br />
          If you choose to play on our Website, there are some general
          guidelines that can help make your playing experience safer, and
          reduce the risk and problems associated with excessive gaming can
          occur:
          <br />
          <br />
          - Purchase Play Money only with amount money that you can afford to
          spend.
          <br />
          <br />
          - DO NOT purchase Play Money with money that you will need for primary
          or essential things such as food, rent, bills.
          <br />
          <br />
          - Pre-plan your spendings and the time you will spend playing on the
          Website and adhere to that limit, regardless of your performance.
          <br />
          <br />
          - Don't play when you are upset, tired, or depressed. It is difficult
          to make good decisions when you are feeling down. Make sure you are in
          your best mental state.
          <br />
          <br />
          - Do not neglect personal and other important tasks just to play.
          Complete all your important tasks so that you can play with a free and
          calm mind.
          <br />
          <br />
          - Balance your playing with other activities. Find other forms of
          entertainment too so playing does not eat up a big part of your life.
          <br />
          <br />
          - DO NOT borrow money just to make purchases on the Website.
          <br />
          <br />
          - Try not using all of your purchased Play Money in a single game or
          session.
          <br />
          <br />
          - Take time outs and breaks regularly.
          <br />
          <br />
          <br />
          <b>UNDERAGE USERS:</b>
          <br />
          <br />
          The Company adheres to a strict policy against underage Users (i.e.
          Users aged below 18 years). To reduce the chance of false/undesired
          purchases and the possibility of the user being under the legal age,
          we ask for identification and documentation if we suspect a User is
          under 18 years. The accounts of any Users identified to be underage
          and playing on the Website will be deactivated or deleted immediately.
          <br />
          <br />
          Although we dedicate a significant amount of time and resources to
          ensure there are no minors playing on the Website, we feel this
          prevention works best as a shared responsibility between us and the
          minor’s parents/guardians. Provided below are a few tips that you can
          follow in order to ensure that minors, such as your children do not
          game on our Website.
          <br />
          <br />
          <br />
          <b>ACCOUNT CLOSURE REQUEST:</b>
          <br />
          <br />
          You can decide to close your account at any time by simply reaching
          out to our Support Team at{" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a>. Please
          note that an account closed under our standard account closure
          mechanism can be reopened at any time by contacting our Support Team.
          However, if you feel you are at risk of developing an unhealthy
          addiction to gaming, we would advise that you consider self-exclusion.
          <br />
          <br />
          <br />
          <b>SELF-EXCLUSION:</b>
          <br />
          <br />
          We offer a self-exclusion feature to help you if gaming is affecting
          you negatively and you want assistance in the same. At your request,
          we will prevent you from using your account for a specific period, as
          determined by you.
          <br />
          <br />
          Similarly, you can set the Deposit Limit, Loss Limit or Session
          Limits. You can also self-exclude for a particular period or
          permanently through settings options. Please write to {" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a> for any
          special request, which will be done if approved by management.
          <br />
          <br />
          Once the period specified by has lapsed, your account will be
          automatically reopened. Please note that any self-exclusion request
          granted is irreversible for the duration of the time for which it was
          originally requested.
          <br />
          <br />
          We ensure to take reasonable steps to prevent you from re-opening your
          Account or opening new accounts with us. However, during the period of
          your exclusion, you must not attempt to re-open your account or to try
          and open new accounts on our Website. You have the option to restrict
          yourself from the marketing emails by sending us an email at {" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a>
          <br />
          <br />
          <br />
          <b>BLOCK BY COMPANY:</b>
          <br /> <br />
          The Company may, at its discretion, temporarily block your access to
          your User Account in case it detects any abnormal or unusual activity
          such as extremely large and frequent purchases of Play Money being
          made on your User Account Website. In such instances, the Company
          shall also reach out to you to make you aware of the potential
          financial implications of your activities. You will receive an email
          from the Support Team upon the Company placing any blocks on your User
          Account.
          <br /> <br />
          The Company may also place a temporary block on your User Account in
          the event the User’s Know Your Customer (KYC) information has not been
          provided to the Company or is outdated until you provide appropriate
          documentation to the Company in order for the Company to verify your
          KYC details.
          <br /> <br /> <br />
          <b>CONSEQUENCES OF EXCLUSION:</b>
          <br />
          <br />
          Please note that any self-exclusion/block/closure placed by the
          Company will apply to your User Account as a whole and that you will
          be prevented from playing any and all games available on the Website
          during any self-exclusion/blockage/closure period.
          <br />
          <br />
          You will be automatically unregistered from any tournaments that begin
          after your User Account is self-excluded/blocked/closed. In addition,
          you will not receive any marketing emails or newsletters from us
          regarding the Website for the period of
          self-exclusion/blockage/closure.
          <br />
          <br />
          <br />
          <b>SETTING LIMITS</b>
          <br />
          <br />
          We offer you the option to set different Responsible Gaming limits to
          help you play responsibly. You can set these limits by yourself on the
          platform or even by contacting support.
          <br />
          <br />
          You may set limits for
          <br />
          <br />
          1. Your real money deposits to the amount you can deposit during a
          specified period. 2. You may choose to completely stop deposits on
          your account but continue to play freerolls and withdraw any winnings
          from the same. 3. Alternatively, you may have your account locked
          which would prevent you from playing on the website.
          <br />
          <br />
          Once any of the above limits has been set, it is irreversible for 7
          days.
          <br />
          <br />
          <br />
          <b>DEPOSIT LIMITS</b>
          <br />
          <br />
          By default, the company permits users to deposit a min of Rs 50 & max
          of Rs 500,000/- in aggregate, over a period of 24hrs. in the event you
          wish to modify your deposit limits. You may do so using the
          Responsible Gaming Self Limitation tool.
          <br />
          <br />
          <br />
          <b>QUERIES</b>
          <br />
          <br />
          In case you have any queries or require any clarifications regarding
          this Responsible Gaming Policy, you can contact us through the
          following channels:
          <br />
          <br />
          Email: <a href="mailto:support@cards52.com">support@cards52.com</a>
          <br />
          <br />
          Cards52 strives to help a player who requests help and support for
          potential negative effects of gaming in every possible way. Users may
          contact us on{" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a> to get
          assistance on responsible gaming.
          <br />
          <br />
          <br />
        </p>
      </Flex>
    </>
  );
}

export default ResponsibleGaming;
