import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function PrivacyPolicy() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/privacy.png`)}
        />
        <pre className="static-page-title">Privacy Policy</pre>
        <p className="static-text text-center">
          This privacy policy forms part of our Website's Terms of Service, by
          accepting the Terms and Conditions and the Privacy Policy on the
          registration page, you consent to providing sensitive personal data or
          personal information and are aware of the purpose of sharing such
          information. Please do not register if you do not wish to share the
          mandatory personal information with <b>Ayron Tech Private Limited</b>{" "}
          (hereinafter referred to as “Ayron” or “Cards52” or “we” or “us” or
          “our”) requested at the time of registration. The registration
          procedure cannot be completed until the information in the
          non-optional fields is provided. Certain additional personal
          information may be requested by Cards52 for permitting you access to
          any Cash Games organized by Cards52.
          <br />
          <br />
          <h3>1. Policy objective</h3>
          <br />
          Cards52 respects your privacy and assures you that any information
          provided by you to Cards52 is protected and will be dealt with
          according to this Policy and the applicable laws. To avail of the
          services offered on{" "}
          <a href="www.cards52.com" target="_blank">
            www.cards52.com
          </a>{" "}
          and connected mobile applications (hereinafter singly referred as the
          "Website" and collectively referred to as “Websites”), you may have to
          provide certain information to us. This Policy provides the procedure
          followed by Cards52 to gather, use, store, disclose, and manage users'
          personal data. Cards52 only collects personal data for the purpose of
          verifying user accounts, maintaining the accounts of the users,
          completing transactions of the users, and analyzing user behavior and
          requirements.
          <br />
          <br />
          <h3> 2. What is included in personal data?</h3>
          <br />
          <br />
          User's personal data includes the following types of personal data or
          information:
          <br />
          <br />
          <b>1. Sensitive Personal Data:</b>
          <br />
          • Account password <br/>• Financial information such as Bank account or
          credit card or debit card or other payment instrument details
          <br />
          <br />
          <b> 2. Other Personal Information:</b>
          <br/>• Name <br/>• Date of birth <br/>• Telephone number <br/>• Postal/Contact address <br/>•
          PAN number (as applicable) <br/>• The IP address of your computer, browser
          type, and language. <br/>• The date and the time during which you accessed
          the site. <br/>• The address of the website which you may have used to link
          to the Website <br/>• Your photograph for testimonials and other
          promotions. <br/>• Such other information that is defined as sensitive
          personal data or information in law.
          <br />
          <br/>
          You shall be entitled to update the information provided by you by
          contacting us at{" "} <a href="mailto:support@cards52.com">
            support@cards52.com
          </a>{" "}
          Your information shall be updated subject to reasonable checks and
          confirmation by Cards52.
          <br />
          <br />
          <h3> 3. User Consents:</h3>
          <br />
          User's personal data includes the following types of personal data or
          information:
          <br />
          <br/>

          <b>
            1. Consent for use of Sensitive Personal Data and Other Personal
            Information:
          </b>
          <br />
          All users of Cards52 consent to the use of Sensitive Personal Data and
          Other Personal Information for the purpose stated in this policy. We
          restrict access to personal information to our employees, contractors,
          and agents and only allow access to those who need to know that
          information in order to process it on our behalf.
          <br />
          <br />
          Users are entitled to withdraw consent for use of their Sensitive
          Personal Data or Other Personal Information by emailing a specific
          request to , <a href="mailto:support@cards52.com" >support@cards52.com</a> as
          the case may be. Withdrawal of consent of all or any part of Sensitive
          Personal Data and Other Personal Information may result in the
          immediate withdrawal of any right to avail the services provided by
          Cards52. <br />
          <br />
          Withdrawal of consent shall not restrict the right of Cards52 to use
          Other Personal Information for analysis of user data, subject to the
          condition that the Other Personal Information used in any analysis is
          not personally identifiable to any individual user.
          <br />
          <br />
          Photographs once provided by you for marketing purposes give Cards52
          irrevocable rights to use it and it is completely Cards52's discretion
          to delete or not use it any further.
          <br />
          <br />
          Cards52 may also use software applications for website traffic
          analysis and to gather statistics, used for advertising and for
          determining the efficacy and popularity of Cards52 among others.
          <br />
          <br />
          Presently, Cards52 is using display advertising and remarketing
          through Google Analytics, wherein third party vendors including
          google, display our ads on sites across the internet and uses first
          party cookies and third party cookies together to inform, optimize and
          serve ads based on visits of visitors to our website. Our visitors are
          free to opt out of Google Analytics for display advertising and
          customize google display network ads using ads preference manager.
          <br />
          <br />
          <b>2. Consent to the use of Cookies:</b>
          <br />
          You understand that when you visit the Website, cookies may be left in
          your computer. The cookies assigned by the servers of Cards52 may be
          used to personalize your experience on the Website. Additionally,
          cookies may also be used for authentication, game management, data
          analysis, and security purposes.
          <br />
          <br />
          Cookies may also be assigned by the advertisers of Cards52 when you
          click on any of the advertisements which may be displayed on any
          Website in which case such cookies are controlled by these advertisers
          and not Cards52.
          <br />
          <br />
          <b>3. Consent to email communication:</b>
          <br />
          When you register your email address with Cards52, you agree to
          receive email communication from Cards52, entities specifically
          authorized by Cards52 and other users. You also agree and acknowledge
          that when you use our referral program for referring someone, Cards52
          will send emails to such person referred by you on your behalf and the
          email headers will carry your email address as the address from which
          such emails are sent.
          <br />
          <br />
          Cards52 may also access and store such information relating to your
          contacts to send invitations and other promotions to them
          periodically.
          <br />
          <br />
          <h3>4. Data Security:</h3>
          <br />
          We take appropriate security measures to protect against unauthorized
          access to or unauthorized alteration, disclosure, or destruction of
          data. These include internal reviews of our data collection, storage,
          and processing practices and security measures, including appropriate
          encryption and physical security measures to guard against
          unauthorized access to systems where we store personal data. Cards52
          has a comprehensive information security program and information
          security policies which contain managerial, technical, operational,
          and physical security control measures adopted by Cards52 for the
          protection of Sensitive Personal Date and Other Personal Information.
          <br />
          <br />
          Information gathered by Cards52 is stored securely using several
          information security applications including firewalls. However,
          security is always relative and Cards52 cannot guarantee that its
          security measures are absolute and cannot be breached. Data which is
          transmitted over the Internet is inherently exposed to security risks
          or threats. For instance, information transmitted via chat or email
          can be compromised and used by others. Therefore, Cards52 cannot
          guarantee any security for such information in the course of
          transmission through the internet infrastructure or any unsolicited
          disclosures made by any user availing the services of the Website.
          <br />
          <br />
          When you register with Cards52, your account is protected by means of
          login information which includes a username and a password that is
          known only to you. Therefore, you should not provide your personal
          information to anyone whosoever, and breach hereof constitutes a
          violation of this Policy and can also result in the closure of account
          in certain cases. If you become aware of or reasonably suspect any
          breach of security, including compromise of your login information, it
          is your responsibility to immediately notify Cards52.
          <br />
          <br />
          The Websites may contain links to other websites. Such websites are
          governed by their own privacy policies and Cards52 does not exercise
          any control over them. It is your responsibility to read and
          understand the privacy policy of such websites when you follow a link
          outside the Website. You are advised to exercise caution in sharing
          any personal information with any third party that advertises on the
          Websites and Cards52 shall not be responsible for such information
          provided by you on third party websites.
          <br />
          <br />
          Cards52 has a policy of not sharing any personally identifiable
          information with anyone other than entities specifically authorized by
          Cards52 which may include advertisers and sponsors of Cards52.
          However, Cards52 may use your name, photo, Login ID, and the state
          from where you are participating when announcing the results of any
          contests run on the Website. Such contests are further governed by
          terms and conditions which shall be available on the Websites as and
          when such a contest is run on any Website. Cards52 conducts periodic
          analysis and survey of the traffic to Cards52.com for market research
          and advertising purposes. Cards52 reserves the right to share your
          registration information with Cards52 appointed market research and
          advertising companies or firms from time to time for the said
          purposes. Cards52 may also use cumulative non-personal information for
          auditing and analysis purposes with the aim of improving its services.
          <br />
          <br />
          <h3>5. Data Retention policy:</h3>
          <br />
          Cards52 shall retain the information provided by the users for such
          period of time as is required to fulfil the purposes for which such
          information is collected, as outlined in this Privacy Policy, subject
          to such longer time periods of retention as may be required under any
          applicable laws of India.
          <br />
          <br />
          <h3>6. Exclusions:</h3>
          <br />
          Cards52 may share Sensitive Personal Data and Other Personal
          Information if sharing of such information is necessary:
          <br />
          <br />
          • to comply with legal processes or governmental requests; <br/>• to
          enforce the Terms of Service and this Privacy Policy; <br/>• for prevention
          of fraud; <br/>• for issues involving information security, or <br/>• to
          protect: <br/>• your rights; <br/>• rights of Cards52; and <br/>• rights of the
          general public.
          <br />
          <br />
          <h3>7. Limitation of Liability:</h3>
          <br />
          Cards52 confirms that this Privacy Policy is only a description of its
          operation regarding user information. This Policy is not intended to
          and does not create any legal rights in your favor or in the favor of
          any other person. <br />
          <br />
          Cards52 reserves the right to change this Policy at any time without
          giving you prior notice. The liability of Cards52 shall be limited to
          removal of Sensitive Personal Data from the system of the Websites and
          removal of personally identifiable elements of the Other Personal
          Information. Notwithstanding anything to the contrary contained in
          this Policy and elsewhere, the aggregate liability of Cards52 for all
          claims for damages pursuant to provisions of services on the Website,
          including claims in respect to the violation of this Policy, shall be
          limited to the aggregate maximum amount of liability as provided in
          the Terms of Service.
          <br />
          <br />
          <h3>8. Grievance Officer:</h3>
          <br />
          Ayron has currently appointed Mr. Aditya as the Grievance Redressal
          Officer of Cards52. All complaints relating to personal data must be
          sent to the Grievance Officer at{" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a> <br />
          <br />
          <br/>
        </p>
      </Flex>
    </>
  );
}

export default PrivacyPolicy;
