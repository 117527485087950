import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function TDS() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/TDS.png`)}
        />
        <Flex className="text-center">
          <pre className="static-page-title">Tax Deducted at Sourcce (TDS)</pre>
        </Flex>
        <p className="static-text">
          Cards52 is a part of the Ayron Tech Private Limited. All deposits and
          cash outs made on Cards52 will be made in the name of Ayron Tech
          Private Limited.
          <br />
          <br />
          All withdrawals done on Cards52 are subject to withholding tax (TDS)
          as per applicable rates (i.e., 30% currently) which is submitted to
          the Indian tax authorities under section 194B of the Income Tax Act.
          <br />
          <br />
          According to Section 194BA of the Income Tax Act the person
          responsible for paying (Cards52 in this case) to any person (our
          players) any income by way of winnings from any lottery or crossword
          puzzle, or card game, and other game of any sort; shall at the time of
          payment thereof or at the end of the financial year, deduct income-tax
          thereon at the rates in force (30% as of now).
          <br />
          <br />
          Therefore, TDS will be applicable at the time of payment and at the
          end of the financial year, which is when a Player’s 'Net Winnings' are
          calculated.
          <br />
          <br />
          Furthermore, Section 194BA states that tax is to be deducted on Net
          Winnings of any amount. Hence, TDS will be calculated for any Net
          Winnings that are &gt; 0 at the time of withdrawal.
          <br />
          <br />
          TDS is calculated at the time of payment on winnings (Withdrawal -
          Deposits). This is calculated at the time of the withdrawal. At the
          time of payment, Winnings of any amount are subject to TDS of 30% and
          payment to the player will be made by Cards52 after deduction of such
          taxes.
          <br />
          <br />
          As an example, A player deposits ₹10,000, and utilizes ₹2,000 of that
          to play a game. He wins ₹15,000. Thus the Cashier Balance will now be
          ₹23,000 of which ₹13,000 is their Net Winnings + ₹10,000 their
          previous deposit.
          <br />
          <br />
          The player requests for a withdrawal of their entire total balance of
          ₹23,000. As per the TDS laws, no amount will be withheld on the
          deposit amount of ₹10,000, no tax will be withheld, and the player
          will be refunded these unused funds.
          <br />
          <br />
          The remaining winning amount of ₹13,000, is subject to 30% withholding
          tax, which will be applicable before the payment is made to the
          player. Therefore 30% of ₹13,000, i.e., ₹3,900 will be deducted as
          taxes and ₹19,100 will be credited to the player’s account.
          <br />
          <br />
          Another example is say a player deposits ₹10,000, and uses ₹2,000 of
          that to play a game. The player wins ₹5,000. Their Cashier Balance is
          now ₹13,000 of which ₹3,000 is their Net Winnings + ₹10,000 their
          previous deposit. The player requests for a withdrawal of their total
          balance of ₹13,000, of which their deposit amount i.e., ₹10,000 will
          be refunded without any tax.
          <br />
          <br />
          Only the net winning amount of ₹3,000 is taxable. 30% withholding tax
          will be applicable before the payment is made to the player. As a
          result, ₹900 will be deducted as taxes and ₹12,100 will be credited to
          the player’s account.
          <br />
          <br />
          <br />
        </p>
      </Flex>
    </>
  );
}

export default TDS;
