import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function AboutUs() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/image 76.png`)}
        />
        <pre className="static-page-title">ABOUT US:</pre>
        <p className="static-text">
          Cards52 is on a mission to revolutionize the Indian gaming scene by
          becoming a one stop solution for all your gaming needs. With a
          determination to keep players first and offering the top-class
          features, the Cards52 team is dedicated to making every game on the
          platform rewarding and entertaining for every player. <br />
          <br />
          The Cards52 team is made up of people who have been around in the
          Indian poker space for years now. Some of them being players
          themselves, they realize the pain points, challenges and envisage the
          scope of improvement in the current practices and offerings. And when
          they come together great things happen! These passionate individuals
          are steadfast in offering the best and only the best online gaming
          experience to the players. <br />
          <br />
          While doing that, the oath of not compromising on the core values of
          player security, upholding the game’s integrity, ensuring responsible
          gaming and fairplay opportunities further drives{" "}
          <a href="Cards52.com" target="_blank">
            Cards52.com
          </a>{" "}
          to aim for excellence. <br />
          <br />
          <p className="text-center">Meet the Cards52 Team: </p>
          <br />
          <br />

          <Image
          className="teammate-img" 
           src={require(`./../../assets/images/ramesh.jpg`)}/>
          <br/>

          <b>Ramesh Thotapalli</b>
          <br />
          Founder and CEO
          <br />
          <br />
          A popular and respected name in the Indian poker space, our founder
          and CEO strives for the best and only the best. Being an active and
          successful poker player himself for years, and having run a live
          casino vessel in Goa, this MIT grad decided to step onto the other
          side of the spectrum and launched his own poker website to offer
          something that the industry has not seen before. With a vision of
          making Cards52 India’s favorite gaming platform, RRT’s inspiring and
          motivating presence keeps the wheels spinning at Cards52.
          <br />
          <br /> Feel free to reach out to RRT at{" "}
          <a href="mailto:ceo@cards52.com">ceo@cards52.com</a>
          <br />
          <br />
          <br />
          <Image
          className="teammate-img" 
           src={require(`./../../assets/images/ismail.jpg`)}/>
          <br/>
          <b>Mohammad Ismail (Mia)</b>
          <br />
          Operations Manager
          <br />
          <br />
          Mia, as he is fondly known, comes with a profound experience of poker.
          Mia, too, is a player himself having played professionally for over a
          decade. He has previously worked with PokerStars, Mad Over Poker, and
          Poker Ninja among others, which helps this knowledgeable and humble
          individual bring a lot to the table and making Cards52 just what a
          player needs.
          <br />
          <br />
          For collaborations and affiliations slide into Mia’s inbox at{" "}
          <a href="mailto:mia@cards52.com">mia@cards52.com</a>
          <br />
          <br />
          <br />
          <Image
          className="teammate-img" 
           src={require(`./../../assets/images/satya.jpg`)}/>
          <br />
          <b>Jay Sayta</b>
          <br />
          Legal Counsel
          <br />
          <br />
          Taking care of everything legal at Cards52 is one of India’s most
          notable gaming lawyers, Jay Sayta. Having spent years in the RMG
          space, Jay provides expert advice and guidance on all the legal
          aspects of the business, while ensuring compliance with the current
          laws and regulations. Jay has been striving towards having the Indian
          RMG sector regulated, which makes him the perfect match for Cards52’s
          revolutionary vision. He is also a part of several regulatory bodies
          and forums that look after the Indian gaming space.
          <br />
          <br />
          Jay is reachable at <a href="mailto:jnsayta@gmail.com">jnsayta@gmail.com</a>
          <br />
          <br />
          <br />
          <b>Shah & Kathariya</b>
          <br />
          Finance & Accounting
          <br />
          <br />
          Looking after the finances and accounts is a financial firm, Shah &
          Kathariya. The team of Shah & Kathariya is headed by senior partners
          who come with 40+ years of experience. This bunch of professional
          chartered accountants understand the nitty gritties of the RMG
          industry and make sure every penny at Cards52 is taken care of.
          <br />
          <br />
          Reach out to Shah & Kathariya at{" "}
          <a href="mailto:accounts@cards52.com">accounts@cards52.com</a>
          <br />
          <br />
          Start grinding on Cards52 and get ready to hold all the cards! Let the
          games begin!!
          <br />
          <br />
        </p>
      </Flex>
    </>
  );
}

export default AboutUs;
