import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function Legality() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/legality.png`)}
        />
        <Flex>
          <pre className="static-page-title">Legality</pre>
        </Flex>
        <p className="static-text text-center">
          Under the Indian Constitution, gambling and betting is a state subject
          and every state has either adopted the central Public Gambling Act,
          1867 or has its own legislation governing the subject of gambling and
          betting.
          <br />
          <br />
          In The State of Bombay v. RMD Chamarbaugwala (AIR 1957 SC 699), the
          Hon’ble Supreme Court recognised that games involving an element of
          skill are separate from gambling and betting, and games of skill enjoy
          the right to free trade and commerce guaranteed by the constitution.
          <br />
          <br />
          This position has subsequently been reaffirmed by the Hon’ble Supreme
          Court and various High Courts in several landmark decisions involving
          the games of rummy, poker, fantasy sports, quiz games etc. Several
          recent judgments of High Courts have in fact reaffirmed this position
          in the context of online games of skill such as poker, rummy and
          fantasy sports and struck down laws passed by state governments that
          imposed a ban on such activities.
          <br />
          <br />
          The games offered by Cards52 have a substantial and predominant
          element of skill in accordance with the test laid down by the Hon’ble
          Supreme Court and various High Courts.
          <br />
          <br />
          Contests and games with real money entry fees or stakes on the Cards52
          website and app are however not available in the states of Andhra
          Pradesh, Telangana, Tamil Nadu, Arunachal Pradesh, Assam, Odisha,
          Nagaland and Sikkim at present. We will update users as and when the
          legal position in these states’ changes. <br />
          <br />
          In addition to the above restricted states, certain specific games may
          not be accessible to users residing in certain states owing to
          judicial orders or legislations. For any doubts or clarifications, you
          may contact <a href="support@cards52.com">support@cards52.com</a>{" "}
          <br />
        </p>
      </Flex>
    </>
  );
}

export default Legality;
