import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function TermsAndConditions() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/TnC.png`)}
        />
        <pre className="static-page-title">Terms & Conditions:</pre>
        <p className="static-text">
          <b>Ayron Tech Private Limited</b> is company registered under the
          Companies Act, 2013 having its registered office at RGM-8/186, Damani
          Bhawan, Nishikanan, Tegharia, Kolkata-700157, Corporate Office at 421
          Gera Imperium Grand, Patto, Panjim, Goa-403001 and Corporate
          Identification Number (CIN)
          <br />  U72200WB2022PTC257110 and owns, operates and  offers online
          skill-based games including and not limited to Rummy, Fantasy sports,
          and Poker through the web-portal{" "}
          <a href="www.cards52.com" target="_blank">
            www.cards52.com
          </a>{" "}
          / Cards52 app, (collectively referred to as the "Portal") (Ayron Tech
          Private Limited is hereinafter referred to as "Cards52" or "we" or
          "us" or "our"). Any person utilizing the Portal or any of its features
          including participation in the various contests, games ("Game")
          ("Amusement Facilities") being conducted on the Portal, be referred to
          as "User" or "you" or "your" and shall be bound by these Terms of
          Service and our Privacy Policy. The services provided on our Portal
          are collectively referred to as the "Services".
          <br />
          <br />
          Due to prevailing state legislations and/or judicial orders, we also
          do not offer our paid games/cash games with entry fees in the states
          of Arunachal Pradesh, Andhra Pradesh, Assam, Odisha, Telangana, Tamil
          Nadu, Nagaland and Sikkim. These states are hereinafter referred to as
          "Restricted States".
          <br />
          <br />
          Further, in addition to the Restricted States, for the game of poker,
          we do not offer cash games/paid services in the state of Gujarat.{" "}
          <br />
          <br />
          Cards52 reserves the right to restrict services for all or any of our
          games in any additional states or union territories where such online
          skill-based games are not permitted by law or judicial order.
          <br />
          <br />
          If you access the Service for making a deposit(s) /withdrawal(s) from
          or playing Game(s) from a jurisdiction where Games are not permitted,
          you shall be entirely liable for any legal or penal consequences and
          we shall be entitled to forfeit the balance in your account.
          <br />
          <br />
          <br />
          Introduction
          <br />
          <br />
          Your use of the products and/or services on the Portal (hereinafter
          referred to as "Services") currently offered or to be offered in the
          future by Ayron Tech Private Limited, its subsidiaries, affiliates,
          licensors, associates and partners (hereinafter referred as "Cards52")
          through the Portal [(which comprises of{" "}
          <a href="www.cards52.com" target="_blank">
            www.cards52.com
          </a>{" "}
          ( referred as "Website") and the Cards52 app ( referred as "App")],
          are all subject to and governed by these Terms of Service, Privacy
          Policy, Fair Play and Legality sections collective or respectively for
          each Website and App including the sub-sections of these sections, as
          the case may be (hereinafter referred as "Terms").
          <br />
          <br />
          You understand that the Terms will be binding on you. You agree that
          Services offered on the Portal can be accessed only in accordance with
          the Terms and you shall be responsible to comply with the Terms at all
          times. You are responsible to be aware of and agreeing to abide by the
          Terms as published and periodically amended or modified by Cards52.
          <br />
          <br />
          If any of the Terms are determined to be unlawful, invalid, void, or
          unenforceable for any reason by any judicial or quasi-judicial body in
          India, it will not affect the validity and enforceability of the
          remaining Terms. Our failure or delay to act or exercise any right or
          remedy with respect to a breach of any of the Terms by you shall not
          be construed as a waiver of our right to act with respect to the said
          breach or any prior, concurrent, subsequent, or similar breaches. If a
          promotion, game, event, competition, or tournament is organized by us
          on a Portal, it shall be governed by these Terms and any supplementary
          terms and conditions which may be specifically applied for that
          promotion, game, event, competition, or tournament (hereinafter
          collectively referred as "Terms" or "Terms of Service").
          <br />
          <br />
          <br />
          APPLICABILITY
          <br />
          <br />
          BEFORE REGISTERING WITH US, YOU SHOULD CAREFULLY READ AND REVIEW THESE
          TERMS PROVIDED BY CARDS52 WHICH ARE APPLICABLE TO ALL THE SERVICES ON
          THE PORTAL AND ALSO THE WEBSITE OR APP-SPECIFIC CONDITIONS AND RULES
          APPLICABLE TO THE SPECIFIC WEBSITE OR APP.
          <br />
          <br />
          Links to the following sections of these Terms are provided below for
          easy access to the sections you intend to refer to. However, you
          should read the Terms as you will be bound by them all. Click on any
          of the following links to access that section directly.
          <br />
          <br />
          • Legality <br />• Game Services <br />• User representations <br />•
          User Account Creation & Operation <br />• User Account validation and
          personal information verification <br />• User restrictions <br />•
          Payments and Player Funds <br />• Withdrawals <br />• Service
          Disruptions <br />• Content <br />• Special Offer / Bundle Offer{" "}
          <br />• Promotions <br />• Loyalty Program <br />• License Agreement &
          Intellectual Property <br />• Voluntary termination <br />• User
          Account suspension <br />• Breach and consequences <br />• Complaints,
          Grievances & disputes <br />• Modifications and alterations <br />•
          Limitation of liability <br />• Disclaimer and indemnity <br />•
          Governing law, dispute resolution & jurisdiction <br />• Responsible
          Game Play
          <br />
          <br />
          <b> 1. Legality</b>
          <br />
          You may only use the Services to Play Cash Games (as defined below) if
          you are 18 years of age or above. Access to our Services or any part
          thereof may be restricted by us from time to time in our sole
          decision. You confirm that you are not accessing the Services to play
          Games requiring Entry Fees from any Restricted State or outside India.
          <br />
          <br />
          <b>2. Game Services</b>
          <br />• All tournaments, promotional games, practice games, and cash
          games organized on the Websites are collectively referred to as “Game”
          or "Games". We reserve the right to match the players on the basis of
          their skill level. The rules applicable to each type of Game are
          provided under the Help section on the respective Website and App.{" "}
          <br />• "Cash Game(s)” or “Games Requiring Entry Fees” are Game(s)
          that require the participant to have a certain minimum cash balance in
          their user account to participate. All other Games offered on the
          Portal are defined as Non-Cash Game(s). <br />• We charge service
          charges for Cash Games, which may vary depending on the nature of the
          Cash Game and are subject to change from time to time. Non-Cash Games
          are offered free on the Portal but may be subject to entry
          restrictions in some cases. Service charges charged by us are
          inclusive of Goods and Services Tax (GST).
          <br />
          <br />
          <b>3. User representations</b>
          <br />
          • Any information provided by you to us, whether at the stage of
          registration or any time subsequently when demanded by us, should be
          complete and truthful. <br />• Prior to adding cash to your user
          account or participating in Cash Games, you shall be responsible to
          satisfy yourself about the legality of playing Cash Games in the
          jurisdiction from where you are accessing Cash Games. If you are not
          legally competent to individually enter into Indian Rupee transactions
          through banking channels in India and/or are not accessing any Website
          from a permitted jurisdiction, you are prohibited from participating
          in Cash Games on the website. In the event of such violation, your
          participation in Cash Games will be deemed to be in breach of the
          Terms and you will not be entitled to receive any prize that you might
          win in such Cash Games. <br />• You represent that you are 18 years of
          age or older to participate in any Games and are also otherwise
          competent to enter into transactions with other users and us. You are
          aware that participation in the Games organized by us ("Activity") may
          result in financial loss to you. With full knowledge of the facts and
          circumstances surrounding this Activity, you are voluntarily
          participating in the Activity and assume all responsibility for and
          risk resulting from your participation, including all risk of
          financial loss. You agree to indemnify and hold us, our employees,
          directors, officers, and agents harmless with respect to any and all
          claims and costs associated with your participation in the Activity.{" "}
          <br />• You represent that you have the experience and the requisite
          skills required to participate in the Activity and that you are not
          aware of any physical or mental condition that would impair your
          capability to fully participate in the Activity. You further
          acknowledge that you are solely responsible for any consequence
          resulting from you participating in this Activity or being associated
          with this Activity or around this Activity. You understand that we
          assume no liability or responsibility for any financial loss that you
          may sustain as a result of participation in the Activity. <br />• You
          understand and accept that your participation in a Game available on
          the Portal does not create any obligation on us to give you a prize.
          Your winning of a prize is entirely dependent on your skill as a
          player vis-a-vis other players in the Game is subject to the Terms and
          the rules of the Game. <br />• You understand and agree that you are
          solely responsible for all content posted, transmitted, uploaded, or
          otherwise made available on the Portal by you. All content posted by
          you must be legally owned by or licensed to you. By publishing any
          content on the Website, you agree to grant us a royalty-free,
          world-wide, non- exclusive, perpetual, and assignable right to use,
          copy, reproduce, modify, adapt, publish, edit, translate, create
          derivative works from, transmit, distribute, publicly display, and
          publicly perform your content and to use such content in any related
          marketing materials produced by us or our affiliates. Such content may
          include, without limitation, your name, username, location, messages,
          gender, or pictures. You also understand that you do not obtain any
          rights, legal or equitable, in any material incorporating your
          content. You further agree and acknowledge that we have the right to
          use in any manner whatsoever, all communication or feedback provided
          by you. <br />• You understand and accept that we reserve the right to
          record any and all user content produced by way of but not limited to
          chat messages on the Portal through our feature, through the in-game
          chat facility, or other interactive features, if any, offered as part
          of the Services. <br />• You understand that the funds in your user
          account held by us do not carry any interest or return. <br />• You
          shall not hold us responsible for not being able to play any Game for
          which you may be eligible to participate. This includes but is not
          limited to situations where you are unable to log into your user
          account or make some or all transactions as your user account may be
          pending validation or you may be suspected or are in established
          violation of any of the Terms. <br />• You understand and accept that
          by viewing or using the Portal or availing of any Services, or using
          communication features on the Portal, you may be exposed to content
          posted by other users which you may find offensive, objectionable or
          indecent. You may bring such content posted by other users to our
          notice that you may find offensive, objectionable or indecent and we
          reserve the right to act upon it as we may deem fit. The decision
          taken by us on this regard shall be final and binding on you.
          <br />
          <br />
          <b>4. User Account Creation & Operation</b>
          <br />
          • To use our Services, you will need to register with us on the
          Portal. <br />• By completing the online registration process on the
          Portal, you confirm your acceptance of the Terms. <br />• By
          registering on our Portal, you agree to receive all communication from
          us including promotional messages relating to Tournaments & Bonus
          through SMS, voice call, Email, and Push Notifications. You may
          withdraw your consent by sending an email to{" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a> <br />• During
          the registration process, you will be required to choose a login name
          and a password in addition to providing some other information which
          may not be mandatory. Additionally, you may be required to give
          further personal information for your user account verification and/or
          for adding cash to your user account. You must give us the correct
          details in all fields requiring your personal information, including,
          without limitation, your name, postal address, email address,
          telephone number(s) etc. You undertake that you will update this
          information and keep it current. <br />• You acknowledge that we may,
          at any time, be required to verify the correctness of this information
          and in order to do so may require additional documentary proof from
          you, failing which we reserve the right to suspend or terminate your
          registration on the Website. <br />• Any information provided by you
          to us should be complete and truthful to the best of your knowledge.
          We are not obliged to cross check or verify information provided by
          you and we will not take any responsibility for any outcome or
          consequence as a result of you providing incorrect information or
          concealing any relevant information from us. <br />• You understand
          that it is your responsibility to protect the information you provide
          on the Portal including but not limited to your Username, Password,
          Email address, Contact Details, and Mobile number. We will not ask for
          your user account login password which is only to be entered at the
          time of login. At no other time should you provide your user account
          information to any user logged in on the Portal or elsewhere. You
          undertake that you will not allow / login and then allow, any other
          person to play from your user account using your username. You
          specifically understand and agree that we will not incur any liability
          for information provided by you to anyone which may result in your
          user account on the Websites being exposed or misused by any other
          person. <br />• You agree to use your user account with us, solely for
          the purpose of playing Games on the Portal and for transactions which
          you may have to carry out in connection with availing the Services
          thereon. Use or attempted use of your user account for any reason
          other than what is stated in the Terms may result in immediate
          termination of your user account and forfeiture of any prize, bonus or
          balance in the user account. <br />• You also understand and agree
          that deposits in your user account maintained with us are purely for
          the purpose of participation in Cash Games made available on the
          Portal. <br />• You understand and agree that you cannot transfer any
          sum from your user account with us to the account of another
          registered user on the Portal except as may be permitted by us and
          subject to restrictions and conditions as may be prescribed by us in
          this regard. <br />• Under Section 194BA of the Income Tax Act, 1961,
          we are legally obliged to deduct tax at source (TDS) on your net
          winnings. In these cases, you will be required to furnish your
          Permanent Account Number (PAN) duly issued to you by the Income Tax
          authorities if you have not already done so. TDS at the rate of 30%,
          or such other rate as may be prescribed by law for the time being in
          force will automatically be deducted from such winnings and the rest
          will be credited to your user account. Withdrawal of these winnings
          will only be permitted upon your providing your correct PAN details.
          These limits and rates are subject to change as per the prevailing
          rules and regulations. Our obligation in this regard is limited to
          deducting TDS as required by law and providing you with an appropriate
          certificate of tax deduction. We neither advise you nor shall in any
          manner be responsible for your individual tax matters. On winning a
          contest with goods or cash or a combination of both as prizes, the TDS
          (Tax Deducted at Source) on the cash prizes won will be borne by you,
          and TDS on the goods will be borne by us. <br />• We reserve the right
          to verify your PAN from time to time and to cancel any prize should
          your PAN be found inconsistent in our verification process.
          <br />
          <br />
          <b>
            {" "}
            5. User Account validation and personal information verification
          </b>
          <br />
          • Cards52 may from time to time attempt to validate its players' user
          accounts. These attempts may be made via a phone call or via email. In
          the event we are not able to get in touch with you the first time
          around, we will make additional attempts to establish contact with
          you. If the phone number and email provided by you is not correct, we
          bear no responsibility for the Services being interrupted due to our
          being unable to establish contact with you. <br />• If we are unable
          to reach you or if the validation is unsuccessful, we reserve the
          right to disallow you from logging into the Portal or reduce your play
          limits, and/or Add Cash limits until we are able to satisfactorily
          validate your user account. We will in such events email you to notify
          you of the next steps regarding user account validation. We may also
          ask you for proof of identification and proof of address from time to
          time. <br />• Upon receipt of suitable documents, we will try our best
          to enable your user account at the earliest. However, it may take a
          few business days to reinstate your user account. <br />• In the event
          that we have made several attempts to reach out to you but have been
          unable to do so, we also reserve the right to permanently suspend your
          user account and refund the amount, if any, in your user account to
          the financial instrument through which the payment was made to your
          user account or by cheque to the address provided by you. In the event
          the address provided by you is incorrect, we will not make any
          additional attempts for delivery of the cheque unless a correct
          address is provided by you and charges for redelivery as prescribed by
          us are paid by you.
          <br /> • The Privacy Policy of our Portal form a part of the Terms and
          Conditions. All personal information which is of such nature that
          requires protection from unauthorized dissemination shall be dealt
          with in the manner provided in the Privacy Policy of the Portal.
          <br />
          <br />
          <b>6. User restrictions</b>
          <br />
          • Anti-Cheating and Anti-Collusion: <br />• You undertake that you
          yourself will play in all Games in which you have registered/joined
          and not use any form of external assistance to play. You shall not add
          unauthorized components, create or use cheats, exploits, bots, hacks
          or any other third-party software designed to modify the Portal or use
          any third-party software that intercepts, mines or otherwise collects
          information from or through the Portal or through any Services. Any
          attempt to employ any such external assistance is strictly prohibited.
          <br />• Formation of teams for the purpose of collusion between you
          and any other user(s) for participating in Games organized on the
          Portal or any other form of cheating is strictly prohibited. <br />•
          When collusion or cheating is detected on Cards52, we shall settle the
          Game as per its "Game Cancellation Settlement Policy" and may take
          further appropriate action against offending users in terms hereof
          including banning access to the Portal and any or all Services. <br />
          • Money Laundering: You are prohibited from doing any activity on the
          Portal that may be construed as money laundering, including, without
          limitation, attempting to withdraw cash from unutilized cash added
          through credit cards or deliberately losing money to a certain
          player(s). <br />• Anti-SPAMMING: Sending SPAM emails or any other
          form of unsolicited communication for obtaining registrations on the
          Portal to benefit from any of our promotional program or for any other
          purpose is strictly prohibited.
          <br /> • Multiple IDs: Your registration on the Portal is restricted
          to a single user account which will be used by you to avail of the
          Services provided on the Portal. You are prohibited from creating or
          using multiple user IDs for registering on the Portal. <br />• You may
          not create a login name or password or upload, distribute, transmit,
          publish or post content through or on the Portal or through any
          service or facility including any messaging facility provided by the
          Websites which: <br />• is libelous, defamatory, obscene,
          intimidating, invasive of privacy, abusive, illegal, harassing; or
          relates to gambling and betting
          <br /> • contains expressions of hatred, hurting religious sentiments,
          racial discrimination or pornography;
          <br /> • is otherwise objectionable or undesirable (whether or not
          unlawful); <br />• would constitute an incitement to commit a criminal
          offence; <br />• violates the rights of any person; <br />• is aimed
          at soliciting donations or other form of help; <br />• violates the
          intellectual property of any person; <br />• disparage in any manner
          Cards52 or any of its subsidiaries, affiliates, licensors, associates,
          partners, sponsors, products, services, or websites; <br />• promotes
          a competing service or product; or <br />• violates any laws <br />•
          In the event we determine that the login name created by you is
          indecent, objectionable, offensive, or otherwise undesirable, we shall
          notify you of the same and you shall promptly provide us with an
          alternate login name so that we can change your existing login name to
          the new name provided by you. If you fail to provide an alternate
          name, we reserve the right to either permanently suspend your user
          account or restore your user account only after a different acceptable
          login name has been provided by you. <br />• You shall not host,
          intercept, emulate or redirect proprietary communication protocols,
          used by the Portal, if any, regardless of the method used, including
          protocol emulation, reverse engineering, or modification of the Portal
          or any files that are part of the Portal. <br />• You shall not frame
          the Portal. You may not impose editorial comments, commercial
          material, or any information on the Websites, alter or modify Content
          on the Portal, or remove, obliterate or obstruct any proprietary
          notices or labels. <br />• You shall not use Services on the Portal
          for commercial purposes including but not limited to use in a cyber
          cafe as a computer gaming center, network play over the Internet or
          through gaming networks, or connection to an unauthorized server that
          copies the gaming experience on the Portal. <br />• You shall not
          upload, distribute or publish through the Portal, any content which
          may contain viruses or computer contaminants (as defined in the
          Information Technology Act 2000, rules framed thereunder or such other
          laws in force in India at the relevant time) which may interrupt,
          destroy, limit the functionality or disrupt any software, hardware or
          other equipment belonging to us or that aids in providing the services
          offered by us. You shall not disseminate or upload viruses, programs,
          or software whether it is harmful to the Websites or not.
          Additionally, you shall not impersonate another person or user,
          attempt to get a password, other user account information, or other
          private information from a user, or harvest email addresses or other
          information. <br />• You shall not purchase, sell, trade, rent, lease,
          license, grant a security interest in, or transfer your user account,
          Content, currency, points, standings, rankings, ratings, or any other
          attributes appearing in, originating from or associated with the
          Portal. <br />• Any form of fraudulent activity including, attempting
          to use or using any other person's credit card(s), debit cards,
          net-banking usernames, passwords, authorization codes, prepaid cash
          cards, mobile phones for adding cash to your user account is strictly
          prohibited. <br />• Accessing or attempting to access the Services
          through someone else's user account is strictly prohibited. <br />•
          Winnings, bonuses and prizes are unique to the player and are
          non-transferable. In the event you attempt to transfer any winnings,
          bonuses or prizes, these will be forfeited. <br />• If you are an
          officer, director, employee, consultant or agent of Cards52 or a
          relative of such persons ("Associated Person"), you are not permitted
          to play either directly or indirectly, any Games which entitle you to
          any prize on the Portal, other than in the course of your engagement
          with us. For these purposes, the term 'relative' shall include spouse
          and financially dependent parents and, children.
          <br />• You shall not post any material or comment, on any media
          available for public access, which in our sole discretion, is
          defamatory, disparaging, harmful or detrimental to our business
          interests, notwithstanding the fact that such media is not owned or
          controlled by us. In addition to any other action that we may take
          pursuant to the provision hereof, we reserve the right to remove any
          and all material or comments posted by you and restrict your access to
          any media available for public access that is either controlled or
          moderate by us; when in our sole opinion, any such material or
          comments posted by you is defamatory or detrimental to our business
          interests. <br />• We do not encourage accessing Cash Games using your
          ID on two or more mobile devices simultaneously. In such an event, we
          reserve the right to disallow the continuation of Cash Games from the
          earlier logged in device(s).
          <br />
          <br />
          <b>7. Payments and Player Funds</b>
          <br />
          • All transactions on the Portal shall be in Indian Rupees. <br />•
          Once you register on our Portal, we maintain a user account for you to
          keep a record of all your transactions with us. Payments connected
          with participation in Cash Games have to be made through your Cards52
          user account. All cash prizes won by you are credited by us into this
          user account. <br />• When making a payment, please ensure that the
          instrument used to make the payment is your own and/or you are legally
          authorized to use such instrument which is used to Add Cash into your
          user account only. <br />• Subject to the Add Cash limits specified by
          us from time to time, you are free to deposit as much money as you
          want in your user account for the purpose of participating in Cash
          Games on the Portal. <br />• Cards52 wants you to play responsibly on
          the Portal. The ability to Add Cash in your user account shall be
          subject to monthly Add Cash limits which we can be set by us with
          undertakings, indemnity, waiver and verification conditions as we deem
          appropriate in our sole discretion. <br />• Credit card, Debit card,
          prepaid cash cards and internet banking payments are processed through
          third party payment gateways. Similarly, other payment modes also
          require an authorization by the intermediary which processes payments.
          We are not responsible for delays or denials at their end and
          processing of payments will be solely in terms of their policies and
          procedures without any responsibility or risk at our end. If there are
          any issues in connection with adding cash, a complaint may be sent to
          us following the complaints procedure provided in "Complaints and
          disputes" section below. You agree that in such an event of your
          credit being delayed or eventually declined for reasons beyond our
          control, we will not be held liable in any manner whatsoever. Once a
          payment/transaction is authorized, the funds are credited to your user
          account and are available for you to play Cash Games. <br />• We have
          the right to cancel a transaction at any point of time solely
          according to our discretion in which case if the payment is
          successful, then the transaction will be reversed, and the money
          credited back to your payment instrument. <br />• Player funds are
          held in trust by us in specified bank accounts. Cards52 keeps all
          players' funds unencumbered which will be remitted to you in due
          course subject to the terms and conditions applicable to withdrawal of
          funds and these Terms. Funds held in your user account are held
          separately from our corporate funds. Even in the highly unlikely event
          of an insolvency proceeding, you claim on the deposits will be given
          preference over all other claims to the extent permissible by law.
          <br />
          <br />
          <b> 8. Withdrawals</b>
          <br />
          • You may withdraw your winnings by means of either electronic
          transfer for the amount of winnings to your designated wallet or bank
          account. <br />• You agree that all withdrawals you make are governed
          by the following conditions:
          <br /> • Cards52 can ask you for Know Your Customer (KYC) documents
          such as Aadhar Card, Passport or other government issued proof of
          identification with photograph to verify your address and identity at
          any stage. Withdrawals will be permitted only from accounts for which
          such KYC process is complete. <br />• You can choose to withdraw money
          from your user account at any time, subject to bonus/prize money
          withdrawal restrictions, by notifying us of your withdrawal request.
          Bonuses and promotional winnings are subject to withdrawal
          restrictions and can only be withdrawn on fulfilling some
          preconditions, one of which is that you have made at least one cash
          deposit on the Portal and thereafter played at least one Cash Game.
          <br /> • Once notified, post verification of the withdrawal request,
          we may disburse the specified amount by cheque or electronic transfer
          based on the mode of withdrawal selected by you. We shall make our
          best efforts to honor your choice on the mode of withdrawal but
          reserve the right to always disburse the specified amount to you by
          cheque. In case of non-availability of your bank account details, the
          Company may require you to provide those details to process the
          refund/ withdrawal. We also reserve the right to disburse the amount
          on the financial instrument used to Add Cash to your user account.{" "}
          <br />• Withdrawals may attract processing/banking charges as per the
          prevalent policy. You may be eligible to make one or more free
          withdrawals in a month depending on various factors including but not
          limited to the amount of withdrawal or the mode of withdrawal. In the
          event that you do not have an adequate amount in your user account to
          pay the processing charge, your withdrawal will not be processed at
          that time. You may request for a free withdrawal subsequently if you
          become eligible for the same at such time. <br />• We will attempt our
          best to process your withdrawals in a timely manner, but there could
          be delays due to the time required for verification and completing the
          withdrawal transaction. We shall not be liable to pay you any form of
          compensation for the reason of delays in remitting payments to you
          from your user account. <br />• To be eligible to win a prize, you
          must be a resident of India and access the Services of Cards52 from
          India (but not from any Restricted State).
          <br /> • If you are a prize winner resident in India and physically
          present in India while accessing the services of Cards52 but not an
          Indian citizen, we will remit your winnings in Indian Rupees to the
          address/bank account given by you, provided, the address and bank
          account is within India.
          <br />
          <br />
          <b> 9. Service Disruptions</b>
          <br />
          • You may face Service disruptions, including, but not limited to
          disconnection or communication interferences due to issues in the
          internet infrastructure used for providing or accessing the Services
          or due to issues with the hardware and software used by you. You
          understand that Cards52 has no control over these factors. Cards52
          shall not be responsible for any interruption in Services and you take
          full responsibility for any risk of loss due to Service interruptions
          for any such reason.
          <br /> • You understand, acknowledge, and agree to the fact that if
          you are unable to play/participate in any Game/ contest/competition
          due to any error or omission whether or not attributable to Cards52,
          including technical or other glitches, the settlement of such Game/
          Contest/ Competition will be settled as per the "Game Cancellation"
          Settlement Policy. <br />• The Company shall, where applicable,
          attempt to start the game immediately once a minimum number of players
          join the game. However, the Company doesn’t commit the same and there
          may be delays in the commencement of the game due to any reason
          whatsoever. The commencement and conclusion of fantasy sports games
          shall be dependent upon the timing of the respective live sports
          events.
          <br />
          <br />
          It is clarified that, notwithstanding any limitation of liability as
          specified herein, Cards52 specifically disclaims any liability in the
          case of Games where there is no money paid by you. You agree that
          under no circumstances shall you compel Cards52 or hold Cards52 liable
          to pay you any amount over and above the service charges for any of
          the aforementioned errors/omissions of Cards52.
          <br />
          <br />
          <b> 10. Content</b>
          <br />• All content and material on the Portal including but not
          limited to information, scores, ranking, winnings, images, marks,
          logos, designs, pictures, graphics, text content, hyperlinks,
          multimedia clips, animation, games and software (collectively referred
          to as "Content"), whether or not belonging to Cards52, are protected
          by law including applicable intellectual property laws. Additionally,
          all chat content, messages, images, recommendations, emails, images
          sent by any user can be logged/recorded by us and shall form part of
          Content and Cards52 is free to use this material in any manner
          whatsoever. In addition, you consent that we are entitled to display
          your User ID, ranking, and/or winnings in one or more contests as part
          of any product/advertisement creatives independently or as a part of
          the list of scores/rankings. <br />• The Portal may contain
          information about or hyperlinks to third parties. In such cases, we
          are not responsible in any manner and do not extend any express or
          implied warranty to the accuracy, integrity, or quality of the content
          belonging to such third-party websites or apps. If you rely on any
          third-party Content posted on any website or app which does not belong
          to Cards52, you may do so solely at your own risk and liability.{" "}
          <br />• If you visit any third-party website or app through a
          third-party content posted on the Portal, you will be subject to terms
          and conditions applicable to such a third-party website or app. We
          neither control nor are responsible for content on such third-party
          websites or apps. The fact of a link existing on our Portal to a
          third-party website or app is not an endorsement of that website or
          app by us.
          <br />
          <br />
          <b> 11. Special Offer / Bundle Offer</b>
          <br />
          • If you are unable to join any one or more Contest(s) in any special
          offer for any reason whatsoever, you will not be able to avail of such
          offer. In such cases, any money deducted from the wallet will be
          refunded to your wallet and you can rejoin any of such contests
          individually as a normal user provided such Contests are available and
          subject to the terms of such Contests.
          <br />
          <br />
          <b> 12. Promotions</b>
          <br />
          • Cards52 offers various kinds of promotions, bonuses, cashbacks and
          contests. The details of various promotions organized on the Websites
          can be found in the Promotions section on that Website. Eligibility
          and applicable conditions for the ongoing promotional programs are
          provided in the Promotions section, which form a part of the Terms. If
          any additional terms and conditions specific to a promotional activity
          are mentioned, they shall be applicable to such promotion in addition
          to these Terms.
          <br /> • Games offered under the Promotions section may be cancelled
          or discontinued by Cards52 at any time without notice without any
          liability on Cards52 whatsoever, except refund of entry fee, if
          applicable.
          <br />
          <br />
          <b> 13. Loyalty Program</b>
          <br />
          Cards52 may have common or separate loyalty programs for its different
          products/ games. Details and applicable terms and conditions of our
          loyalty program can be accessed on the respective Help sections. Our
          loyalty program(s) or the terms thereof may be altered or discontinued
          by us at any time without any notice and without any liability on
          Cards52 whatsoever. In addition, Cards52 may, but will not be
          obligated to, run the loyalty program(s) which may entail grant of
          reward points or other loyalty rewards of any nature whatsoever, which
          may be redeemed in a manner prescribed in its terms specified thereof.
          Cards52 reserves the right at its sole discretion to terminate its
          loyalty program(s), if any, and revoke any unredeemed loyalty points
          or loyalty rewards with or without notice. Cards52 shall not be liable
          to pay any compensation for loyalty points or loyalty rewards
          remaining in your user account in any event whatsoever, including,
          without limitation, the discontinuation of such loyalty programs by
          Cards52.
          <br />
          <br />
          <b> 14. License Agreement & Intellectual Property</b>
          <br />
          • All Content on the Portal shall be utilized only for the purpose of
          availing Services in conformity with the Terms. <br />• You
          acknowledge that all ownership rights and all copyright and other
          intellectual property rights in the Content are owned by Cards52 or
          our licensors and that you have no right title or other interest in
          any such items except as expressly stated in the Terms. <br />• You
          are granted a personal, non-exclusive, non-assignable and
          non-transferable license to use the Content solely for the purposes of
          accessing and using the Services and for no other purpose whatsoever.{" "}
          <br />• You shall not sublicense, assign or transfer the license
          granted to you, or rent or lease or part with the whole or any part of
          such license or of the Content included in such license.
          <br /> • You may not transfer, copy, reproduce, distribute, exploit,
          reverse engineer, disassemble, translate, decode, alter, make
          derivations from or make any other use of Content on the Websites in
          any manner other than as permitted for obtaining the Services provided
          on the website.
          <br /> • You may not hyperlink the Portal to any other website without
          permission from us. <br />• You may access information on download and
          print extracts from the Portal for your personal use only. No right,
          title or interest in any downloaded materials or software is
          transferred to you by downloading and you are expressly prohibited
          from using such materials for any commercial purpose unless agreed
          with us in writing.
          <br />
          <br />
          <b> 15. Voluntary termination</b>
          <br />• You are free to discontinue the use of the Services on any
          Website at any time by intimating us of your desire to do so by
          sending an email to us at{" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a> <br />• If at
          such time, there is a positive withdrawable cash balance in your user
          account, we will, subject to satisfactory verification, disburse the
          same to you by online transfer or by a cheque in a timely manner.
          <br />
          <br />
          <b> 16. User Account suspension</b>
          <br />
          • We may suspend or otherwise put restrictions on your access to the
          Services on the Websites during investigation for any of the following
          reasons:
          <br /> • Suspected violation of Terms or other abuse of your user
          account;
          <br /> • Suspected breach of security of your user account; or <br />•
          If there have been charge-backs on your user account.
          <br />
          <br />
          Our decision to suspend or restrict Service or any part thereof as we
          deem appropriate shall be final and binding on you.
          <br />
          <br />
          <b> 17. Breach and consequences</b>
          <br />
          • In the event of a breach of any of the Terms being evidenced from
          our investigation or if there is a reasonable belief, in our sole
          discretion, that your continued access to the Portal or any Website is
          detrimental to the interests of Cards52, our other users or the
          general public; we may in our sole discretion take any or all of the
          following actions: <br />• Restrict games between users suspected of
          colluding or cheating; <br />• Permanently suspend your user account
          on the Website; <br />• Forfeit the cash balance in your user account;{" "}
          <br />• Demand damages for breach and take appropriate civil action to
          recover such damages; and/or <br />• Initiate prosecution for
          violations that amount to offences in law. <br />• Additionally, in
          the event of committing material breach hereof, we reserve the right
          to bar you from future registration on the Website. <br />• The
          decision of Cards52 on the action to be taken as a consequence of
          breach shall be final and binding on you. <br />• Any action taken by
          Cards52 shall be without prejudice to our other rights and remedies
          available in law or equity.
          <br />
          <br />
          <b> 18. Complaints, Grievances & disputes</b>
          <br />• If you have a complaint or concern, you should in the first
          instance contact the customer support team at{" "}
          <a href="mailto:support@cards52.com" >support@cards52.com</a> or write to us
          following the procedure given in the respective Contact Us section on
          the relevant Websites/App. Complaints should be made as soon as
          possible after circumstances arise that cause you to have a complaint.
          <br />• You accept that any complaints and disputes are and remain
          confidential both whilst a resolution is sought and afterwards. You
          agree that you shall not disclose the existence, nature, or any detail
          of any complaint or dispute to any third party. <br />• Cards52 shall
          make efforts to resolve complaints within a reasonable time. <br />•
          Our decision on complaints shall be final and binding on you. <br />•
          For any grievances, you can reach out to Grievance Redressal Officer
          at <a href="mailto:support@cards52.com" >support@cards52.com</a>
          <br />
          <br />
          <b> 19. Modifications and alterations</b>
          <br />
          • We may alter or modify the Terms at any time without giving prior
          notice to you. We may choose to notify of some changes in the Terms
          either by email or by displaying a message on the Portal; however, our
          notification of any change shall not waive your obligation to keep
          yourself updated about the changes in the Terms. Your continued use of
          any Portal and/or any Services offered constitutes your unconditional
          acceptance of the modified or amended Terms. <br />• We may also post
          supplementary conditions for any Services that may be offered. In such
          an event, your use of those Services will be governed by the Terms as
          well as any such supplementary terms that those Services may be
          subject to.
          <br />
          <br />
          <b> 20. Limitation of liability</b>
          <br />
          • In addition to specific references to limitation of liability of
          Cards52 elsewhere in the Terms, under no circumstances (including,
          without limitation, in contract, negligence, or other tort), Cards52
          shall be liable for any injury, loss, claim, loss of data, loss of
          income, loss of profit or loss of opportunity, loss of or damage to
          property, general damages or any direct, indirect, special,
          incidental, consequential, exemplary or punitive damages of any kind
          whatsoever arising out of or in connection with your access to, or use
          of, or inability to access or use, the Services on any Website or App
          in the Portal. You further agree to indemnify us and our service
          providers and licensors against any claims in respect of any such
          matter. <br />• Without limiting the generality of the foregoing, you
          specifically acknowledge, agree and accept that we are not liable to
          you for: <br />• the defamatory, undesirable or illegal conduct of any
          other user of the Services; <br />• any loss whatsoever arising from
          the use, abuse or misuse of your user account or any feature of our
          Services on the Websites;
          <br /> • any loss incurred in transmitting information from or to us
          or from or to our websites by the internet or by other connecting
          media; <br />• any technical failures, breakdowns, defects, delays,
          interruptions, improper or manipulated data transmission, data loss or
          corruption or communications’ infrastructure failure, viruses or any
          other adverse technological occurrences arising in connection with
          your access to or use of our Services; <br />• the accuracy,
          completeness or currency of any information services provided on the
          Websites and Apps; <br />• any delay or failure on our part to
          intimate you where we may have concerns about your activities; and{" "}
          <br />• your activities / transactions on third party websites or apps
          Portal accessed through links or advertisements posted in the Portal.{" "}
          <br />• Notwithstanding anything to the contrary contained in the
          Terms or elsewhere, you agree that our maximum aggregate liability for
          all your claims under this agreement, in all circumstances, other than
          for the payment of any withdrawable balance in your user account,
          shall be limited to Indian Rupees Ten Thousand only (INR. 10,000/-)
          <br />
          <br />
          <b> 21. Disclaimer and indemnity</b>
          <br />
          <br />
          Disclaimer
          <br />
          <br />• The Services on the Websites and the Content present on it are
          provided strictly on "as is" basis with all faults or failings. Any
          representations, warranties, conditions or guarantee whatsoever,
          express or implied (including, without limitation, any implied
          warranty of accuracy, completeness, uninterrupted provision, quality,
          merchantability, fitness for a particular purpose or non-infringement)
          are specifically excluded to the fullest extent permitted by law.
          Cards52 does not ensure or guarantee continuous, error-free, secure or
          virus- free operation of the Portal or its Content including software,
          Games, your user account, the transactions in your user account or
          continued operation or availability of any facility on the website.{" "}
          <br />• Additionally, Cards52 does not promise or ensure that you will
          be able to access your user account or obtain Services whenever you
          want. It is entirely possible that you may not be able to access your
          user account or the Services provided by Cards52 at times or for
          extended periods of time due to, but not limited to, system
          maintenance and updates. <br />• Cards52 disclaims responsibility and
          liability for any harm resulting from cancellation of any Game
          organized by it. If you are a cash player on the Portal, you
          acknowledge and agree that you will not be entitled to any refund in
          case of any service outages that may be caused by failures of our
          service providers, computer viruses or contaminants, natural
          disasters, war, civil disturbance, or any other cause beyond the
          reasonable control of Cards52. <br />• Cards52 specifically disclaims
          any liability in connection with Games or events made available or
          organized on the Portal which may require specific statutory
          permissions, in the event such permissions are denied or cancelled
          whether prior to or during such Game or event.
          <br /> • Cards52 specifically disclaims any liability in connection
          with your transactions with third parties which may have
          advertisements or are hyperlinked on the Website. <br />• Cards52
          disclaims any liability in connection with violation of intellectual
          property rights of any party with respect to third party Content or
          user content posted on our website. Intellectual property rights in
          any Content not belonging to us belong to the respective owners and
          any claims related to such content must be directly addressed to the
          respective owners. <br />• Cards52 specifically disclaims any
          liability arising out of the acts or omissions of the infrastructure
          providers or otherwise failure of internet services used for providing
          and accessing the Services.
          <br /> • Cards52 disclaims liability for any risk or loss resulting to
          you from your participation in Cash Games, including all risk of
          financial loss.
          <br />
          <br />
          Indemnity
          <br />
          <br />
          • To the extent permitted by law, and in consideration for being
          allowed to participate in the Activity, you hereby agree to indemnify,
          save and hold harmless and defend us (to the extent of all benefits
          and awards, cost of litigation, disbursements and reasonable
          attorney's fees that we may incur in connection therewith including
          any direct, indirect or consequential losses, any loss of profit and
          loss of reputation) from any claims, actions, suits, taxes, damages,
          injuries, causes of action, penalties, interest, demands, expenses
          and/or awards asserted or brought against us by any person in
          connection with: <br />• infringement of their intellectual property
          rights by your publication of any content on our website. <br />•
          defamatory, offensive or illegal conduct of any other player or for
          anything that turns out to be misleading, inaccurate, defamatory,
          threatening, obscene or otherwise illegal whether originating from
          another player or otherwise; <br />• use, abuse or misuse of your user
          account on our website in any manner whatsoever; <br />• any
          disconnections, technical failures, system breakdowns, defects,
          delays, interruptions, manipulated or improper data transmission, loss
          or corruption of data or communication lines failure, distributed
          denial of service attacks, viruses or any other adverse technological
          occurrences arising in connection with your access to or use of our
          website; and <br />• access of your user account by any other person
          accessing the Services using your username or password, whether or not
          with your authorization.
          <br />
          <br />
          <b> 22. Governing law, dispute resolution & jurisdiction</b>
          <br />
          • The Terms and Conditions and Privacy Policy shall be interpreted in
          accordance with the laws of India. <br />• Any dispute, controversy,
          or claim arising out of the Terms and Conditions or Privacy Policy
          shall be subject to the exclusive jurisdiction of the civil courts at
          Goa, India.
          <br />
          <br />
          <b> 23. Responsible Game Play</b>
          <br />
          We encourage all players to play responsibly. We are committed to
          helping players who wish to stop playing or who wish to limit the
          amount that they play.
          <br />
          <br />
        </p>
      </Flex>
    </>
  );
}

export default TermsAndConditions;
