import React,{useEffect} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/Home";
import Contact from "./pages/staticPages/Contact";
import HoldemPoker from "./pages/staticPages/Holdem";
import Omaha from "./pages/staticPages/Ohama";
import Rummy from "./pages/staticPages/Rummy.js";
import RewardStore from "./pages/staticPages/RewardStore";
import AboutUs from "./pages/staticPages/About";
import TermsAndConditions from "./pages/staticPages/Terms&Conditions";
import PrivacyPolicy from "./pages/staticPages/PrivacyPolicy";
import Legality from "./pages/staticPages/Legality";
import FAQs from "./pages/staticPages/FAQs";
import TDS from "./pages/staticPages/TDS";
import Blog from "./pages/staticPages/Blog";
import Promotions from "./pages/staticPages/Promotions";
import RNGcertification from "./pages/staticPages/RNGCertification";
import RakeStructure from "./pages/staticPages/RakeStructure";
import TournamentSchedule from "./pages/staticPages/TournamentSchedule";
import LoginModal from "./components/login";
import { useState } from "react";
import ResponsibleGaming from "./pages/staticPages/ResponsibleGaming";
import HowToPlayFantasy from "./pages/staticPages/Fantasy";

function App() {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  useEffect(() => {
    console.log('App.ts ceck');
    window.storeAndGetGLCookie();
  },[]);

  return (
    <Router>
      <Header openLoginModal={toggleLoginModal} />
      {true && (
        <LoginModal onClose={toggleLoginModal} isOpen={showLoginModal} />
      )}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home" exact component={Home} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact-us" component={Contact} />
        <Route path="/holdem-poker" component={HoldemPoker} />
        <Route path="/omaha" component={Omaha} />
        <Route path="/rummy" component={Rummy} />
        <Route path="/reward-store" component={RewardStore} />
        <Route path="/terms-conditions" component={TermsAndConditions} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/legality" component={Legality} />
        <Route path="/FAQs" component={FAQs} />
        <Route path="/TDS" component={TDS} />
        <Route path="/blog" component={Blog} />
        <Route path="/promotions" component={Promotions} />
        <Route path="/RNG-certification" component={RNGcertification} />
        <Route path="/rake-structure" component={RakeStructure} />
        <Route path="/tournament-schedule" component={TournamentSchedule} />
        <Route path="/responsible-gaming" component={ResponsibleGaming} />
        <Route path="/fantasy" component={HowToPlayFantasy} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
