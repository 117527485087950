import React from "react";
import {
  Box,
  Center,
  Heading,
  Input,
  Button,
  VStack,
  Container,
  Text,
  Flex,
  Image,
  Divider,
} from "@chakra-ui/react";
import "./style.css";

function Home() {
  return (
    <>
      <Flex flexDirection="row">
        <Box
          className="home-container"
          backgroundImage={require(`./../assets/images/home_1.png`)}
          // backgroundSize="cover"
          // backgroundPosition="center"
          // minHeight="100vh"
          // display="flex"
          // alignItems="center"
          // justifyContent="center"
          // flex="1"
        >
          <Container>
            <VStack spacing={4} align="start">
              <Heading color="white" fontSize="3xl" textAlign="start">
                <div className="home-text">Join The Gaming Revolution On</div>

                <Flex flexDirection="row">
                  <Flex className="blue-text">Cards</Flex>
                  <Flex className="red-text">52</Flex>
                </Flex>
              </Heading>
              <div className="sub-text">Download The Cards52 Mobile App</div>

              <Flex>
                <Input
                  className="mobile-input"
                  placeholder=" + 91  |  Enter your mobile number"
                  variant="filled"
                  size="lg"
                />
                <Button className="get-link-button">Get Link</Button>
              </Flex>
            </VStack>
          </Container>
        </Box>
        <Box
          className="home-container"
          backgroundImage={require(`./../assets/images/home_2.png`)}
          // backgroundSize="cover"
          // backgroundPosition="center"
          // minHeight="100vh"
          // display="flex"
          // alignItems="center"
          // justifyContent="center"
          // flex="1"
        ></Box>
      </Flex>

      <Flex className="top-games-box" flexDirection="column">
        <Flex className="top-game-title">
          Top Games on<div className="blue-text">Cards52</div>
          <div className="red-text">.com</div>
        </Flex>
        <Flex flexDirection="row" gap={200} pl="70px" alignSelf="center">
          <Flex flexDirection="column">
            <Image
              className="top-game-img"
              src={require(`./../assets/images/cards.png`)}
            />
            <Image
              className="top-game-img-base"
              src={require(`./../assets/images/pngegg.png`)}
            />
            <Text className="game-name">RUMMY</Text>
          </Flex>
          <Flex flexDirection="column">
            <Image
              className="top-game-img"
              src={require(`./../assets/images/Acard.png`)}
            />
            <Image
              className="top-game-img-base"
              src={require(`./../assets/images/pngegg.png`)}
            />
            <Text className="game-name">OTHERS</Text>
          </Flex>
          <Flex flexDirection="column">
            <Image
              className="top-game-img"
              src={require(`./../assets/images/cricket.png`)}
            />
            <Image
              className="top-game-img-base"
              src={require(`./../assets/images/pngegg.png`)}
            />
            <Text className="game-name">FANTASY</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex className="box" flexDirection="column">
        <Flex flexDirection="column" gap={20}>
          <Flex className="box-title">Our Values</Flex>

          <Flex className="box-sub-title">
            At Cards52 we take pride in our values that are aimed at putting our
            players’ interests first.
          </Flex>

          <Flex gap={20}>
            <Flex flexDirection="column" gap={100}>
              <Box>
                <Flex flexDirection="column" gap={20}>
                  <Image
                    className="box-img"
                    src={require(`./../assets/icons/Vector.png`)}
                  />
                  <Flex className="box-heading">Quick Withdrawals</Flex>
                  <Flex className="box-text">
                    Easy access to your funds at any time.
                  </Flex>
                </Flex>
              </Box>
              <Box>
                <Flex flexDirection="column" gap={20}>
                  <Image
                    className="box-img"
                    src={require(`./../assets/icons/Cashier.png`)}
                  />
                  <Flex className="box-heading">Responsible Gaming</Flex>
                  <Flex className="box-text">
                    We ensure responsible gaming practices on Cards52 through
                    deposit limits, cash table limits and more.
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            <Flex flexDirection="column" gap={100}>
              <Box>
                <Flex flexDirection="column" gap={20}>
                  <Image
                    className="box-img"
                    src={require(`./../assets/icons/Subtract.png`)}
                  />
                  <Flex className="box-heading">Player Protection</Flex>
                  <Flex className="box-text">
                    We use the best and safest technology to store players
                    personal and financial data to ensure utmost player
                    protection.
                  </Flex>
                </Flex>
              </Box>
              <Box>
                <Flex flexDirection="column" gap={20}>
                  <Image
                    className="box-img-2"
                    src={require(`./../assets/icons/Group.png`)}
                  />
                  <Flex className="box-heading">Game integrity</Flex>
                  <Flex className="box-text">
                    As one of India's leading online poker rooms, we uphold the
                    game's integrity by following fair and trustworthy
                    practices.
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex className="blue-box">
        {/* <Flex flexDirection="row"> */}
        <Box
          className="fog-img"
          backgroundImage={require(`./../assets/images/fog-left.png`)}
        >
          <Flex flexDirection="row" className="text-center">
            <Flex className="img-box" flexDirection="column">
              <Box className="center-box">
                <Image
                  className="group-img"
                  src={require(`./../assets/icons/support.png`)}
                />
              </Box>
              <Flex flexDirection="column">
                <Box className="blue-box-text">PROMPT AND FRIENDLY</Box>
                <Box className="blue-box-text">PLAYER SUPPORT</Box>
                {/* <Box className="blue-box-text-bold">20 Lac+</Box> */}
              </Flex>
            </Flex>

            <Flex className="img-box" flexDirection="column">
              {/* <Box className="center-box"> */}
              <Image
                className="group-img center-box"
                src={require(`./../assets/icons/wallet.png`)}
              />
              {/* </Box> */}
              <Flex flexDirection="column">
                <Box className="blue-box-text">REWARDING PROMOTIONS</Box>
              </Flex>
            </Flex>

            <Flex className="img-box" flexDirection="column">
              <Box className="center-box">
                <Image
                  className="group-img"
                  src={require(`./../assets/icons/chip.png`)}
                />
              </Box>
              <Flex flexDirection="column">
                <Box className="blue-box-text">24x7 CASH</Box>
                <Box className="blue-box-text">TABLES AND TOURNAMENTS</Box>
              </Flex>
            </Flex>
            {/* <Box
            className="fog-img"
            backgroundImage={require(`./../assets/images/fog-right.png`)}
             /> */}
            {/* </Flex> */}
          </Flex>
        </Box>
      </Flex>

      <Flex className="box">
        <Flex className="box-title">Tutorials</Flex>
        <Flex gap={20} mt="100px">
          <a href="/holdem-poker">
            <Image
              className="tutorial-img"
              // href=""
              src={require(`./../assets/images/poker-tutorial.jpg`)}
            />
          </a>
          <a href="/rummy">
            <Image
              className="tutorial-img"
              src={require(`./../assets/images/rummy-tutorial.jpg`)}
            />
          </a>
          <a href="/fantasy">
          <Image
            className="tutorial-img"
            src={require(`./../assets/images/fantasy-tutorial.jpg`)}
          />
          </a>
        </Flex>
      </Flex>

      <Flex className="top-games-box">
        <Image
          className="arrow-image"
          src={require(`./../assets/images/arrow-left.png`)}
        />
        <Flex>
          <Box
            className="video"
            backgroundImage={require(`./../assets/images/Screen Shot 1.png`)}
          />
          <Flex flexDirection="column">
            <Flex flexDirection="row">
              <pre className="video-title-black">HOW TO PLAY </pre>
              <pre className="video-title-blue">POKER</pre>
            </Flex>
            <Text className="video-text">
              Poker is a fun and rewarding game that challenges you at every
              step. Easy to learn and interesting to play, this game of skill
              will require to put on your thinking cap, and take strategical,
              mathematical and logical decisions.
              <br />
              <br />
              Read our blog to learn how to play poker.{" "}
              <a href="/holdem-poker">click here</a>
            </Text>
          </Flex>
        </Flex>
        <Image
          className="arrow-image"
          src={require(`./../assets/images/arrow-right.png`)}
        />
      </Flex>

      <Flex className="blank" />
    </>
  );
}

export default Home;
