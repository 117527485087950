import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function FAQs() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/FAQs.png`)}
        />
        <Flex className="text-center">
          <pre className="static-page-title">
            Frequently Asked Questions (FAQ)
          </pre>
        </Flex>
        <p className="static-text">
          <b> Is Poker/Rummy/Fantasy legal in India?</b> <br /><br/>
          Betting and gambling are listed under Entry 34 of the State list in
          the Indian Constitution. As such, the Indian Constitution allows
          states to enforce laws to regulate betting and gambling in their
          respective jurisdictions.
          <br />
          <br />
          Various states in India exercise this power independently and have
          enacted anti-gambling legislations within their jurisdictions
          conforming to the principal act of Public Gambling Act of 1867 which
          includes the exception of “games of skill”. Therefore, state laws
          prevail over this act.
          <br />
          <br />
          In this regard, the legislative acts of a certain number of states
          prohibits all games involving money as stakes and do not make an
          exception for games of skill. Players from all other states in India
          can follow their passion and continue playing online
          poker/rummy/fantasy for stakes on credible platforms like Cards52.
          <br />
          <br />
          <b>What are the restricted states to play Real Money Games?</b> <br /><br/>
          Restricted states which restrict playing real money games in India
          currently include Assam, Odisha, Nagaland, Andhra Pradesh, Gujarat,
          Telangana and Tamil Nadu. Currently, residents of these states are not
          allowed to play for real money on online Gaming platforms. That said,
          players can still play free games and tournaments for leisure.
          <br />
          <br />
          <b>What is the age limit for playing Real Money Games?</b>
          <br />
          The legal age for paying Real Money Games is 18 years and above. These
          games can only be played by individuals who are above the age of 18
          years.
          <br />
          <br />
          <b>How to create an account on Cards52?</b>
          <br />
          To create your account on Cards52, simply click on register and fill
          in the required details.
          <br />
          <br />
          <b>I have deposited and still not credited?</b>
          <br /><br/>
          In case you have deposited your money, but it still hasn’t been
          credited into your account, kindly check your bank statement if the
          money has been debited from your account. If the money has not been
          debited you may try depositing again. <br />
          In case the money has been debited from your account kindly wait for
          sometime as there can be technical issues from the bank or merchant
          paying gateways.If the issue persists even after a few hours write to
          us at <a href="mailto:contact@cards52.com">contact@cards52.com</a>
          <br />
          <br />
          <b>How do I Update my personal details?</b>
          <br /><br/>
          Personal details like phone number, email Id, etc can be updated at
          the time of registration. If you want to further make changes, it can
          be done by emailing on{" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a>
          <br />
          <br />
          <b>How do I change my screen name?</b>
          <br /><br/>
          To change your screen name you may send us a request on{" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a>. It is
          advisable to send 2-3 preferred screen names in case the names sent
          are already taken and not available.
          <br />
          <br />
          <b>How do I check my transaction history?</b>
          <br /><br/>
          Follow the steps given below to check your transaction history on
          Cards52: <br />
          Login to your Cards52 account
          <br />
          Click on "My Transactions"
          <br />
          Select "Date"
          <br />
          <b>I forgot my user id. What should I do?</b>
          <br /><br/>
          We can help you recover your user id in case you forgot it. Simply
          email us your query mentioning that you don't remember your user id on{" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a> from your
          registered email id.
          <br />
          <br />
          <b>I forgot my password. What should I do?</b>
          <br /><br/>
          We can also help you recover your password if you’ve forgotten it.
          Simply follow the steps given below:
          <br />
          <br />
          Visit the cards52.com website
          <br />
          • Click on "Login" tab
          <br />
          • Click on "Forgot password"
          <br />
          • Enter your "Email address" and "Username"
          <br />
          • An email with a temporary password will be sent on your registered
          email id. Use the temporary password to login.
          <br />
          • Once you have logged in, change your password for security purposes.
          <br />
          <br />
          <b>How can I change my bank account details on my Cards52 account?</b>
          <br /><br/>
          Unfortunately bank account details once added cannot be deleted.
          However, you can add new bank account details while requesting your
          cash out.
          <br />
          <br />
          <b>How long is the withdrawal process?</b>
          <br /><br/>
          At Cards52 we follow a quick and instant withdrawal process. Post
          successful verification, your money will be credited in your account
          within a few minutes.
          <br />
          <br />
          <b>How is TDS calculated?</b>
          <br /><br/>
          As per the current tax regulations, the Income Tax Act regulations are
          applicable on all Cash withdrawals. At the time of withdrawal, a TDS
          of 30% is deducted from the user's net winnings. This means, the
          player will receive an amount only post the applicable tax is deducted
          into their Bank account.
          <br />
          <br />
          Example:
          <br />
          As an example, A player deposits ₹10,000, and utilizes ₹2,000 of that
          to play a game. He wins ₹15,000. Thus the Cashier Balance will now be
          ₹23,000 of which ₹13,000 is their Net Winnings + ₹10,000 their
          previous deposit.
          <br />
          <br />
          The player requests for a withdrawal of their entire total balance of
          ₹23,000. As per the TDS laws, no amount will be withheld on the
          deposit amount of ₹10,000, no tax will be withheld, and the player
          will be refunded these unused funds.
          <br />
          <br />
          The remaining winning amount of ₹13,000, is subject to 30% withholding
          tax, which will be applicable before the payment is made to the
          player. Therefore 30% of ₹13,000, i.e., ₹3,900 will be deducted as
          taxes and ₹19,100 will be credited to the player’s account.
          <br />
          <br />
          <b>Can I have more than one Gaming account?</b>
          <br /><br/>
          No, a player is warned against having multiple accounts. Cards52
          prohibits having more than one account per user. Each player is
          allowed to make only one account on the site. Having multiple accounts
          may result in all the duplicate accounts being banned or blocked.
          <br />
          <br />
          If any/all of the multiple accounts are found to be involved in any
          malicious activities, it may result in deletion of all the accounts of
          the user and confiscation of the funds available.
          <br />
          <br />
          <b>Can I play for free?</b>
          <br /><br/>
          Yes, you can play these games for free on Cards52. We have multiple
          freerolls running, which are free to enter tournaments that require
          zero buy-in.
          <br />
          <br />
          <b>How can I download the Cards52 app?</b>
          To download the Cards52 app, simply visit the Cards52 website and
          click on the download option. Follow the instructions and give the
          necessary permissions to install the app.
          <br />
          <br />
          <b>Can I delete/deactivate my Cards52 account?</b>
          <br /><br/>
          Yes you can delete or deactivate your Cards52 account. Simply write to
          us at <a href="mailto:support@cards52.com">support@cards52.com</a> and
          we will do the job for you. However, it is advised that you
          temporarily deactivate your account instead of deleting it in case in
          future you wish to continue playing with us.
          <br />
          <br />
          <b>How to reactivate my Cards52 account?</b>
          <br /><br/>
          To reactivate your Cards52 account, you may write to us at{" "}
          <a href="mailto:support@cards52.com">support@cards52.com</a>.
          <br />
          <br />
          <br/>
        </p>
      </Flex>
    </>
  );
}

export default FAQs;
