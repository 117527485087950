import {
  Box,
  Flex,
  Grid,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function Rummy() {
  return (
    <>
      <Flex flexDirection="column">
        <Box
          className="static-page-img"
          backgroundImage={require(`./../../assets/images/image 80.png`)}
        />
        <Flex className="text-center">
          <pre className="static-page-title">How to Play </pre>
          <pre className="static-page-title-blue">RUMMY</pre>
        </Flex>
        <p className="static-text">
          One of the most widely played card games in India is Rummy. It is
          almost like a household game. People often play rummy with or without
          money for fun at festivals, weddings, and other gatherings. For years
          now, rummy has been instrumental in bringing people together and
          bonding them over a game of cards.
          <br />
          <br />
          With the evolution of technology and the onset of online gaming, this
          interesting game made its way into the digital world. Today, rummy can
          be played virtually on the screens and on handheld mobile devices on
          the go. Today several online platforms offer online games, so you need
          not wait for the next family gathering to play your favorite game or
          convince your friends to play. Online rummy allows you to play with
          people across borders.
          <br />
          <br />
          Besides, it provides an amazing opportunity to earn great cash
          rewards. The Indian online rummy space has scaled unprecedented
          heights in the past few years. With popular celebrities and
          personalities promoting the game of skill, the prize pools also keep
          getting bigger and better.
          <br />
          <br />
          In this guide we’ve covered the basics of how to play rummy. So read
          on to know.
          <br />
          <br />
        </p>
        <Flex className="static-page-title">What is Rummy?</Flex>
        <p className="static-text">
          Rummy has been a household card game in India, with the 13 Card Rummy
          being the most popular one. It is also known as Indian Rummy. The game
          involves drawing and discarding cards to make sequences. It is
          typically done utilizing two decks and the two Joker cards. A game of
          rummy requires minimum two and maximum six players to play.
        </p>
        <br />
        <br />
        <Flex className="static-page-title">
          What are the rules for playing Rummy?
        </Flex>
        <p className="static-text">
          The rules for playing rummy are pretty basic to learn. Thus anyone can
          play this exciting game. The game includes two piles of cards placed
          on the table. One of the pile is kept closed, while the second pile is
          kept open, also known as the discard pile deck.
          <br />
          <br />
          Each player gets 13 cards at the start of the game. According to the
          rules of rummy, every player must create a minimum of two sequences,
          out of which one must be pure. The second sequence can be pure or
          impure. A player is permitted to make a maximum of two sets with the
          same cards representing different suits. Players can declare only if
          they have a pure sequence. Declaring without a pure sequence can lead
          to players receiving penalty points or even losing the game.
          <br />
          <br />
          Thus here is what a valid and legitimate declaration should look like:
          <br />
          2 sequences + 2 sets
          <br />
          3 sequences + 1 set
          <br />
          <br />
          The main aim in a game of rummy is to be the first player to create
          proper rummy sets and sequences. Upon setting valid combinations, a
          player can declare and claim victory.
          <br />
          <br />
        </p>
        <Flex className="static-page-title">Rummy Terms</Flex>
        <p className="static-text">
          Before you dive into the game and start playing, it is advisable to
          acquaint yourself with the terms and jargons that are used in rummy.
          Here are some words and phrases you must know:
          <br />
          <br />
          <b>• Table :</b>
          <br />
          A normal game of 13 cards rummy is played at a table. The table may be
          virtual or physical. As per rummy rules, a game of rummy can only
          begin when there are 2 players at the table. Similarly, a table allows
          a maximum of 6 players. which might be digital or actual. According to
          the rummy rules, there are 2 to 6 players at each table.
          <br />
          <br />
          <b>• Deck :</b>
          <br />
          There are 52 cards in a deck, plus a printed joker. There are four
          suits (Clubs, Hearts, Spades, and Diamonds) in a deck, each having 13
          cards each. Every card has a different suit rank.
          <br />
          <br />
          <b>• Wild Cards and Joker Cards :</b>
          <br />
          Every open deck includes a printed Joker Card. Once the cards have
          been dealt, a wild card is then selected at random. Players can make
          use of joker card as well as the wild card to make sets and impure
          sequences.
          <br />
          <br />
          <b>• Sorting :</b>
          <br />
          Sorting cards in rummy means arranging the cards in order after they
          are handed at the start of the game. To ensure the formation of sets
          and sequences and mixing cards, each player must arrange their cards
          in a specific order.
          <br />
          <br />
          <b>• Drop :</b>
          <br />
          Each player in a game of rummy has the option to exit the game at any
          time, however, remember that this results in a penalty.
          <br />
          <br />
          <b>• Draw and Discard :</b>
          <br />
          In a game of rummy, players can choose to draw or pick cards from the
          closed pile or the open pile (pile of cards containing the discarded
          by players and placed face up).
          <br />
          During every turn, a player must draw a card and discard an unwanted
          card from their hand. The actions of picking new cards and discarding
          the unwanted cards is known as drawing and discarding, respectively.
          <br />
          <br />
          <b>• Meld </b>
          <br />
          Every time the cards have been dealt, players need to arrange their
          cards in sequences, or sequences and sets. The action of arranging the
          given cards in such valid groups is known as melding.
          <br />
          <br />
          <b>• Printed & Wild Joker </b>
          <br />
          Jokers have an important role to play in a rummy game. There are
          majorly two types of jokers: printed jokers (1 per deck) and wild
          jokers (4 per deck). There is one printed joker in each deck, while
          there are four wild jokers in each deck. Both these jokers can be used
          as substitutes for any missing cards in the formation of a set or
          sequence.
          <br />
          At the beginning of the game, one random card is selected as the wild
          joker. The cards having the same rank in all the four suits also
          become wild jokers.
          <br />
          <br />
          <b>• Declare </b>
          <br />
          Once you have formed the required sets and sequences and have finished
          the game by discarding one of your cards to the “Finish” slot, you
          must show your cards to your opponents. This act is known as declaring
          your hand.
          <br />
          <br />
          <b>• First Life </b>
          <br />
          The first pure sequence created by the player is known as the first
          life.
          <br />
          <br />
          <b>• Second Life </b>
          <br />
          The second pure sequence or the first impure sequence is called a
          second life. Remember that a second life is invalid without a first
          life.
          <br />
          <br />
        </p>

        <Flex className="static-page-title">How to Play Rummy?</Flex>
        <p className="static-text">
          So far we have discussed the basic rules of rummy. Two deck of cards
          are used to play a game of rummy between two and six players. Each
          players gets 13 cards and one card is selected at random to become the
          game's wild joker.
          <br />
          <br />
          Apart from the wild joker the printed joker from the deck can also be
          used to make valid sets and sequences. The players need to draw new
          cards and discard old ones on every turn to create sets and sequences
          holding the 13 cards.
          <br />
          <br />
          As per the rules of Indian Rummy, a player may declare victory once
          they have organized 13 cards into two valid sequences, having one pure
          sequence, and additional sequences or sets.
          <br />
          <br />
        </p>

        <Flex className="static-page-title">How to create sequences?</Flex>
        <p className="static-text">
          A sequence in rummy is a combination of a minimum of three consecutive
          cards belonging to the same suit. There are two types of sequences in
          rummy namely, pure sequence and impure sequence. In order to make a
          valid declaration a player must have at least one pure sequence.
          <br />
          <br />
          <b>Pure sequence</b>
          <br />
          In rummy, a pure sequence is a combination including at least three
          consecutive cards having the same suit. Players are not allowed to use
          a wildcard or joker to make a pure sequence, hence the name.
          <br />
          For example, a hand having 7h 9h Th is a pure sequence. Similarly, 3s
          4s 5s 6s is also a pure sequence containing no joker.
          <br />
          <br />
          <b>Impure sequence</b>
          <br />
          Just like a pure sequence, an impure sequence is also a combination
          containing at least three consecutive cards, having the same suit. But
          there's a small difference. In an impure sequence, players are
          permitted to use one or even more joker cards to complete their
          sequence.
          <br />
          For example, 5d 6d Kc 8d is an impure sequence, wherein the wildcard
          joker Kc replaces the 7d in the sequence.
          <br />
          <br />
        </p>

        <Flex className="static-page-title">How to create sets?</Flex>
        <p className="static-text">
          A set in 13 rummy card games is a combination of a minimum of three
          cards, belonging to different suits but of the same value or rank.
          Players are allowed to include joker or wild cards, for replacing the
          missing cards to complete your rummy set.
          <br />
          <br />
          An example of a rummy set is 7d 7h 7c 7s. Similarly 8d 8h Qs 8s is
          also a valid set wherein, Qs is a joker replacing 8c.
          <br />
          <br />
          Remember, the formation of a set having two or more cards belonging to
          the same suit is not allowed and thus it will be considered as an
          invalid declaration. On the other hand, a valid set of five cards can
          also be created by using more than 4 cards and a Joker.
          <br />
          <br />
        </p>

        <Flex className="static-page-title">
          How Are Points Calculated In Rummy?
        </Flex>
        <p className="static-text">
          The game of rummy ultimately depends on the accumulation of points.
          The aim of every player must be to minimize the penalty points.
          <br />
          Let us have a look at how points are calculated when you are playing
          an online rummy card game.
          <br />
          <br />
          <Grid mt="20px">
            <Table className="table text-center">
              <Thead>
                <Tr>
                  <Th>Card</Th>
                  <Th>Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>High Value Cards Ace, King, Queen, Jack</Td>
                  <Td>All carry 10 points each</Td>
                </Tr>
                <Tr>
                  <Td>Joker and Wild Cards</Td>
                  <Td>Zero points</Td>
                </Tr>
                <Tr>
                  <Td>
                    Other cards <br />
                    Example: 5 ♥, 6 ♥ 10 ♥
                  </Td>
                  <Td>
                    Have point value same as their face value <br />5 points, 6
                    points, 10 points
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Grid>
          <br />
          <br />
        </p>
        <Flex className="static-page-title">Losing Player Points</Flex>
        <br />
        <p className="static-text">
          <Grid mt="20px">
            <Table className="table text-center">
              <Tbody>
                <Tr>
                  <Td>
                    If the player doesn’t have 2 sequences including a pure
                    sequence
                  </Td>
                  <Td>The value of all cards is added, capped at 80 points</Td>
                </Tr>
                <Tr>
                  <Td>
                    If the player has formed 2 sequences including a pure
                    sequence
                  </Td>
                  <Td>
                    Value of cards that are not included in sequence are
                    calculated
                  </Td>
                </Tr>
                <Tr>
                  <Td>Wrong Declaration</Td>
                  <Td>80 points</Td>
                </Tr>
                <Tr>
                  <Td>First Drop</Td>
                  <Td>20 points</Td>
                </Tr>
                <Tr>
                  <Td>Middle Drop</Td>
                  <Td>40 points</Td>
                </Tr>
                <Tr>
                  <Td>3 Consecutive Misses</Td>
                  <Td>Considered as middle drop with 40 points loss</Td>
                </Tr>
                <Tr>
                  <Td>Leave Table</Td>
                  <Td>
                    If a player leaves the table after picking from a closed
                    deck, it is considered a middle drop. <br />
                    If the player hasn’t picked any card, it is taken as the
                    first drop.
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Grid>
          <br />
          <br />
        </p>
        <br />
        <br />
        <p className="static-text">
          Examples of Points Calculation With Winning Amount
          <br />
          Here are some examples of points calculated on a table of 6 players,
          wherein Q♦ is a wild joker.
          <br />
          <Grid mt="20px">
            <Table className="table text-center">
              <Thead>
                <Tr>
                  <Th>Player</Th>
                  <Th>Hand Formed</Th>
                  <Th>Points Calculated</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Player 1</Td>
                  <Td>
                    2<span className="red-icon">♥</span> 3
                    <span className="red-icon">♥</span> 4
                    <span className="red-icon">♥</span> | 5♣ 6♣ Q
                    <span className="red-icon">♦</span> | 8
                    <span className="red-icon">♦</span> 8♠ 5♣ | 2
                    <span className="red-icon">♦</span> 2♣ | K♠ Q♠
                  </Td>
                  <Td>
                    The player has 2 sequences 1 pure and 1 impure. So, only the
                    points of extra cards will be counted = 45
                  </Td>
                </Tr>
                <Tr>
                  <Td>Player 2</Td>
                  <Td>
                    4♠ 4<span className="red-icon">♥</span> 4♣| 4
                    <span className="red-icon">♦</span> 5
                    <span className="red-icon">♦</span> Q
                    <span className="red-icon">♦</span> | 3♠ 7♠ 8♠ | Q
                    <span className="red-icon">♦</span> K
                    <span className="red-icon">♦</span> | 10♣ 9♣
                  </Td>
                  <Td>
                    The player has not formed 2 sequences, including the pure
                    sequence. Hence, points of all cards will be calculated = 68
                  </Td>
                </Tr>
                <Tr>
                  <Td>Player 3</Td>
                  <Td>
                    3<span className="red-icon">♥</span> 4
                    <span className="red-icon">♥</span> 5
                    <span className="red-icon">♥</span> | 5♣ 6♣ 7♣ Q
                    <span className="red-icon">♦</span> | 8
                    <span className="red-icon">♦</span> 5♣ | 2
                    <span className="red-icon">♦</span> 2♣ 2
                    <span className="red-icon">♥</span> | K♠
                  </Td>
                  <Td>
                    The player has 2 sequences, 1 pure and 1 impure. It also has
                    1 set formed. The points will be calculated only for the
                    unused cards = 23
                  </Td>
                </Tr>
                <Tr>
                  <Td>Player 4</Td>
                  <Td>
                    A<span className="red-icon">♥</span> 4
                    <span className="red-icon">♥</span> 5
                    <span className="red-icon">♥</span> | 5♣ 6♣ 10♣ J
                    <span className="red-icon">♦</span> | 8
                    <span className="red-icon">♦</span> 5♣ | 2
                    <span className="red-icon">♦</span> 2♣ Q
                    <span className="red-icon">♥</span> | K♠
                  </Td>
                  <Td>First Drop with a loss of 20 points</Td>
                </Tr>
                <Tr>
                  <Td>Player 5</Td>
                  <Td>
                    4♠ 4<span className="red-icon">♥</span> 4♣| 4
                    <span className="red-icon">♦</span> 5
                    <span className="red-icon">♦</span> Q
                    <span className="red-icon">♦</span> | A♠ 7♠ 8♠ | Q
                    <span className="red-icon">♦</span> K
                    <span className="red-icon">♦</span> | J♣ 9♣
                  </Td>
                  <Td>3 consecutive misses = 40 points</Td>
                </Tr>
                <Tr>
                  <Td>Player 6</Td>
                  <Td>
                    2<span className="red-icon">♥</span> 3
                    <span className="red-icon">♥</span> 4
                    <span className="red-icon">♥</span> | 5♣ 6♣ 7♣ Q
                    <span className="red-icon">♦</span> | 5
                    <span className="red-icon">♦</span> 5♣ 5
                    <span className="red-icon">♥</span> | 2
                    <span className="red-icon">♦</span> 2♣ 2
                    <span className="red-icon">♥</span>
                  </Td>
                  <Td>WINNER</Td>
                </Tr>
              </Tbody>
            </Table>
          </Grid>
          <br />
          <br />
          Referring to the above guide is all you need to know to get started
          with playing rummy online. Remember that playing rummy online can be
          fun and also help you win some great cash rewards, all you need to do
          is play thoughtfully and keep getting better at the game.
          <br />
          <br />
          Whether you are a regular rummy player or starting new, Cards52 can be
          your go to option. This is because playing rummy online on Cards52
          will give you a hassle free, and top class experience of online rummy.
          Plus, you can enjoy your favorite game anytime anywhere.
          <br />
          <br />
          <br />
        </p>
      </Flex>
    </>
  );
}

export default Rummy;
