import { Box, Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import "./staticPages.css";

function RNGcertification() {
  return (
    <>
      <Flex className="certification-box" flexDirection="row">
        <Flex flexDirection="column" width="50%">
          <Image
            className="RNG-top-left"
            src={require(`./../../assets/images/top-left.png`)}
          />
          <Text className="RNG-heading-left">
            Our RNG is certified by iTech labs
          </Text>
          <Text className="RNG-text">
            Random number generator or RNG is the algorithm that runs card games
            and ensures that there is statistical randomness in numbers that are
            generated which allows every player an equal chance to win. This
            ensures game honesty and transparency which is a vital part of our
            fair play policy at Cards52.com. An RNG certificate is a seal of
            approval from a certified testing body that affirms online gaming
            platforms’ adherence to RNG standards. These standards ensure that
            cards, game outputs are statistically random and unpredictable. This
            is a testament to a trusted gaming ecosystem and ensures just and
            honest gameplay.
          </Text>
          <Text className="RNG-underline-text">
            Click here to view the Original iTech Labs Certificate.
          </Text>
          <Box
            className="RNG-stamp"
            backgroundImage={require(`./../../assets/images/RNG-stamp.png`)}
          />
        </Flex>
        <Flex flexDirection="column" width="50%">
          {/* <Text className="RNG-heading-right">Certificate</Text> */}
          {/* <Image
            className="RNG-certificate"
            src={require(`./../../assets/images/RNG-certification.png`)}
          /> */}
          <Image
            className="RNG-bottom-right"
            src={require(`./../../assets/images/bottom-right.png`)}
          />
        </Flex>
      </Flex>
    </>
  );
}

export default RNGcertification;
